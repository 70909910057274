import Box from '@mui/material/Box';
import Container from '~/components/Container';
import Typography from '~/components/Typography.tsx';
import { Promotion } from '~kup/models/Promotion.ts';
import useProductsKeepState from '~/hooks/useProductsKeepState.ts';
import Stack from '~/components/Stack.tsx';
import { Product } from '~kup/models/Product.ts';
import ImageView from '~/components/ImageView.tsx';
import Badge from '~/components/Badge.tsx';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTimeRemains from '~/hooks/useTimeRemains.ts';
import useKintStore from '~kint/store';

type PromotionSectionProps = {
  promotion: Promotion;
};

export default function PromotionSection(props: PromotionSectionProps) {
  const { promotion } = props;
  const { products } = useProductsKeepState(
    `tag:${promotion.id}`,
    { promotionId: promotion.id },
    { pageSize: 8 }
  );

  if (!products?.length) return null;

  return (
    <Box
      sx={{
        overflow: 'clip',
        backgroundColor: '#5462E7',
        backgroundImage: 'url("/promotion_bg.png")',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        py: '20px',
      }}
    >
      <PromotionHeader
        title={promotion.title}
        subTitle={promotion.subTitle}
        description={promotion.description}
      />
      <Box
        sx={{
          overflowX: 'scroll',
        }}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-evenly'}
          alignItems={'center'}
          px={'20px'}
          pt={'20px'}
          pb={'8px'}
          gap={'12px'}
        >
          {products.map((product) => (
            <PromotionProduct product={product} />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

function PromotionProduct(props: { product: Product }) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { product } = props;
  const { id, price, originalPrice, discountRate, stocks } = product;
  const isSoldOut = !stocks.quantity;
  const isInPromotion = product.isInPromotion;
  return (
    <Stack
      sx={(theme) => ({
        width: 'calc(50% - 12px)',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
      })}
      onClick={() => {
        navigate(`/shop/product/${id}`);
      }}
    >
      <ImageView
        sx={{
          width: '100%',
        }}
        src={product.imageUrls[0]}
      />
      <Stack
        flexDirection={'column'}
        gap={'4px'}
        sx={{
          p: '10px',
        }}
      >
        <Typography
          variant={'FootNote'}
          sx={{
            height: '44px',
            overflow: 'clip',
            textOverflow: 'ellipsis',
          }}
        >
          {product.name}
        </Typography>
        <Stack row gap={'4px'}>
          {price !== originalPrice && price < originalPrice ? (
            <Typography component={'span'} variant={'BaseM_B'} fontWeight={'bold'} color={'error'}>
              {(discountRate * 100).toFixed(0)}%
            </Typography>
          ) : null}
          <Typography component={'span'} variant={'BaseM_B'} fontWeight={'bold'}>
            ₩{price.toLocaleString()}
          </Typography>
        </Stack>
        <Stack row gap={'6px'}>
          {isSoldOut && (
            <Badge color={'error'} size={'small'}>
              {i18n.t('component-promotionSection-soldOut')}
            </Badge>
          )}
          {!isSoldOut && isInPromotion && (
            <Badge color={'error'} size={'small'}>
              {i18n.t('component-promotionSection-hotDeal')}
            </Badge>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

function PromotionHeader(props: { title: string; subTitle: string; description: string }) {
  const { title, subTitle, description } = props;
  const { reservation } = useKintStore((state) => ({ reservation: state.reservation }));
  const reservationDate = reservation?.date;
  const promotionUntil = new Date(new Date(`${reservationDate}T23:59+09:00`).getTime() + 60 * 1000);
  const { remains } = useTimeRemains({ targetDate: promotionUntil });

  return (
    <Container
      stackProps={{
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Stack gap={'6px'}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
            fontFamily: 'SBAggro',
            textTransform: 'capitalize',
            color: 'white',
            textAlign: 'center',
          }}
        >
          {subTitle}
        </Typography>
        <Typography
          sx={{
            fontSize: '28px',
            fontWeight: 'bold',
            fontFamily: 'SBAggro',
            textTransform: 'capitalize',
            textAlign: 'center',
            color: '#93FFB1',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 300,
            fontFamily: 'SBAggro',
            color: 'white',
            textAlign: 'center',
          }}
        >
          {description}
        </Typography>
      </Stack>
      <Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '32px',
            fontWeight: 'bold',
            fontFamily: 'SBAggro',
            color: 'white',
            letterSpacing: '4px',
            '& > div': {
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',
              height: '40px',
              borderRadius: '4px',
              backgroundColor: 'white',
            },
            '& > div > span': {
              display: 'inline-block',
              color: theme.palette.text.primary,
              mt: '6px',
              ml: '2px',
            },
            '& > span': {
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: '6px',
              height: '40px',
              textAlign: 'center',
            },
          })}
        >
          <div>
            <span>{remains.text[0]}</span>
          </div>
          <div>
            <span>{remains.text[1]}</span>
          </div>
          <span>:</span>
          <div>
            <span>{remains.text[3]}</span>
          </div>
          <div>
            <span>{remains.text[4]}</span>
          </div>
          <span>:</span>
          <div>
            <span>{remains.text[6]}</span>
          </div>
          <div>
            <span>{remains.text[7]}</span>
          </div>
        </Box>
      </Box>
    </Container>
  );
}
