import { ReactNode } from 'react';
import { default as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import { default as MuiTab } from '@mui/material/Tab';
import { Typography, TypographyProps } from '@mui/material';

export type Tab = {
  label: ReactNode;
  value: string | number;
};
export type TabsProps = MuiTabsProps & {
  tabs: Tab[];
  scrollable?: boolean;
  type?: 'normal' | 'toggle';
  tabLabelVariant?: TypographyProps['variant'];
};

export default function Tabs(props: TabsProps) {
  const { tabs, scrollable = false, tabLabelVariant = 'BaseM_B', ...restProps } = props;
  return (
    <MuiTabs {...restProps} variant={scrollable ? 'scrollable' : 'fullWidth'}>
      {tabs.map((t) => (
        <MuiTab
          key={t.value}
          label={<Typography variant={tabLabelVariant}>{t.label}</Typography>}
          value={t.value}
        />
      ))}
    </MuiTabs>
  );
}
