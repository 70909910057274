import { Transaction } from '~kup/models/Order.ts';

export enum BenefitTypeEnum {
  DELIVERY_FEE = 'DELIVERY_FEE',
  DISCOUNT = 'DISCOUNT',
  DISCOUNT_RATE = 'DISCOUNT_RATE',
}

export interface ICoupon {
  id: string;
  name: string;
  benefitType: BenefitTypeEnum;
  benefitValue: number;
  description: string;
  issueAbleStartDate: Date;
  issueAbleEndDate: Date;
  activateDuration: number;
  minOrderAmount: number;
  maxDiscountAmount: number;
  isActive: boolean;
  isIssuable: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IIssuedCoupon {
  id: string;
  couponCode: string;
  coupon: ICoupon;
  ownedAt: Date;
  appliedTransaction: Transaction;
  usableUntil: Date;
  isAvailable: boolean;
  unavailableReason?: string;
  applicableDiscountAmount?: number;
  appliedDiscount: number;
  usedAt: Date;
  issuedAt: Date;
  updatedAt: Date;
}
