import { useDeferredValue, useState } from 'react';
import useKupStore from '~kup/store';
import { useNavigate } from 'react-router-dom';
import Page from '~/components/Page';
import Headline from '~/components/Headline.tsx';
import Container from '~/components/Container.tsx';
import TextField, { TextFieldProps } from '~/components/TextField.tsx';
import Typography from '~/components/Typography.tsx';
import List from '~/components/List';
import useSearchAddressWithDebounce from '~/pages/shop/address/hooks/useSearchAddressWithDebounce.ts';
import ListItem from '~/components/ListItem.tsx';
import Icon from '~/components/Icon';
import { useTranslation } from 'react-i18next';

export default function ShopAddressSearchPage() {
  const { inputAddress, setInputAddress, setDeliveryAddress } = useKupStore((state) => ({
    setInputAddress: state.setInputAddress,
    setDeliveryAddress: state.setDeliveryAddress,
    inputAddress: state.inputAddress,
  }));

  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const [searchAddress, setSearchAddress] = useState<string>(inputAddress ?? '');
  const deferredSearchAddress = useDeferredValue(searchAddress);

  const searchedList = useSearchAddressWithDebounce(deferredSearchAddress);

  const handleInputAddress: TextFieldProps['onChange'] = (e) => {
    setSearchAddress(e.target.value ?? '');
  };

  return (
    <Page type={'page'}>
      <Container py={'8px'}>
        <Container pt={'16px'} pb={'20px'} px={'20px'}>
          <Headline
            headline={
              i18n.t('shop-address-whereToDeliver') // Where should we deliver it?
            }
            size={'large'}
            description={
              i18n.t('shop-address-seoulDeliveryOnly') // Currently, delivery is only available in the Seoul area
            }
          />
        </Container>
        <Container p={'8px'} px={'20px'}>
          <TextField
            fullWidth
            placeholder={
              i18n.t('shop-address-hotelNameAddress') // Hotel Name, Address...
            }
            value={searchAddress}
            onChange={handleInputAddress}
          />
        </Container>

        {searchAddress.length === 0 ? (
          <Container py={'16px'} px={'20px'}>
            <ul>
              <li>
                <Typography variant={'FootNote_B'} color={'typography.secondary'}>
                  {
                    i18n.t('shop-address-hotelNameOrStayPlace') // Name of Hotel or where you stay at
                  }
                </Typography>
              </li>
              <li>
                <Typography variant={'FootNote_B'} color={'typography.secondary'}>
                  {
                    i18n.t('shop-address-givenSpecificAddress') // Given specific address
                  }{' '}
                  (ex. Banporo 34gil 32)
                </Typography>
              </li>
            </ul>
          </Container>
        ) : (
          <Container py={'8px'}>
            <List component={'ol'}>
              {searchedList.map((address) => {
                const handleClick = () => {
                  navigate(`/shop/address/${address.id}/search-detail`, {
                    state: { address, inputAddress: searchAddress },
                  });
                  setInputAddress(searchAddress);
                  setDeliveryAddress({
                    address: address,
                    placeName: address.displayName.text ?? '',
                    searchText: searchAddress,
                    googleAddress: address.formattedAddress,
                  });
                };
                return (
                  <ListItem
                    key={address.id}
                    type={'headline'}
                    headline={address.displayName.text}
                    description={address.formattedAddress}
                    onClick={handleClick}
                    trail={<Icon color={'typography.quaternary'}>chevron_right</Icon>}
                  />
                );
              })}
            </List>

            <Typography
              variant={'Caption'}
              color={'typography.tertiary'}
              textAlign={'center'}
              component={'p'}
            >
              Powered by Google
            </Typography>
          </Container>
        )}
      </Container>
    </Page>
  );
}
