import api from '~kup/api';
import { OrderPostData } from '~kup/models/Order.ts';

export interface PortOnePaymentParams {
  key: string;
  pmtChannel: string;
  pmtMethod: string;
  amount: number;
  currency: string;
  environment: 'live' | 'sandbox';
  description: string;
  responseType: string;
  successUrl: string;
  failureUrl: string;
}

export interface PortOneInitiatePaymentParams {
  pmtChannel: string;
  pmtMethod: string;
  amount: number;
  currency: string;
  successUrl: string;
  failureUrl: string;
  billingDetails: {
    billingName: string;
    billingEmail: string;
    locale: string;
  };
  orderDetails: {
    id: string;
    name: string;
    quantity: number;
    price: number;
    image: string;
  }[];
}

export interface PortOneRequestParams {
  merchant_details: {
    name: string;
    promo_discount: number;
    shipping_charges: number;
    logo?: string;
    back_url?: string;
    promo_code?: string;
  };
  merchant_order_id: string;
  signature_hash: string;
  environment: 'live' | 'sandbox';
  amount: number;
  currency: string;
  description: string;
  country_code: string;
  success_url: string;
  failure_url: string;
  show_items?: boolean;
  expiry_hours?: number;
  is_checkout_embed?: boolean;
  show_back_button?: boolean;
  show_shipping_details?: boolean;
  default_guest_checkout?: boolean;
}

export interface IPortOne {
  new (config: { portOneKey: string; jwtToken: string }): {
    checkoutService: {
      checkout: (data: PortOneRequestParams) => void;
    };
    paymentService: {
      payment: (
        data: PortOnePaymentParams,
        failure: (error: any) => void,
        success: (resp: any) => void
      ) => void;
    };
  };
}

declare global {
  interface Window {
    PortOne: IPortOne;
  }
}

export async function initialPayment(orderRequest: OrderPostData) {
  const { orderId, paymentMethod } = orderRequest;
  const portOneChannel = 'PORTONE_KR';
  const currency = 'KRW';
  const amount =
    orderRequest.orderAmount +
    orderRequest.deliveryFee -
    (orderRequest.appliedCoupon?.applicableDiscountAmount ?? 0);
  const failureUrl = `${window.location.origin}/shop/order/fail`;
  const successUrl = `${window.location.origin}/shop/order/transaction?paymentMethod=${paymentMethod}&orderId=${orderId}`;

  const paymentOption = {
    amount,
    currency,
    appliedCoupon: orderRequest.appliedCoupon,
    pmtChannel: portOneChannel,
    pmtMethod: paymentMethod,
    inputAddress: orderRequest.inputAddress,
    orderAddress: orderRequest.deliveryAddress,
    customerContact: orderRequest.contact,
    customerName: orderRequest.customerName,
    customerEmail: orderRequest.customerEmail,
    description: `${orderRequest.orderItems[0].productName} 외 ${
      orderRequest.orderItems.length - 1
    }개`,
    successUrl: successUrl,
    failureUrl: failureUrl,
    billingDetails: {
      billingName: orderRequest.customerName,
      billingEmail: orderRequest.customerEmail,
      locale: 'en',
    },
    orderDetails: orderRequest.orderItems.map((item) => ({
      id: item.id,
      name: item.productName,
      quantity: item.quantity,
      price: item.stock.price,
      image: item.productImageUrls[0],
    })),
  } as PortOneInitiatePaymentParams;

  const initiatePaymentRes = await api.post('/portone/initiate-payment', paymentOption);
  const paymentLink = initiatePaymentRes.data.data.paymentLink;
  window.location.href = paymentLink;
}
