import { useCallback, useLayoutEffect } from 'react';
import { getProducts } from '~kup/controllers/product.ts';
import { Product, ProductCategory } from '~kup/models/Product.ts';
import { useKeepState } from '~/hooks/useKeepState.ts';
import throttle from 'lodash/throttle';

type PageNumber = number; // 1 보다 크거나 같은 정수;
type CategoryType = ProductCategory['name'] | AllCategoryType;
type AllCategoryType = 0 | typeof ProductCategory.CATEGORY_ALL;

function isAllCategoryType(category: CategoryType): category is AllCategoryType {
  return category === 0 || category.toLowerCase() === 'all';
}

type UseProducts = {
  products: Product[];
  hasNextPage: boolean;
  page: number;
  next: (targetPage?: number) => void;
  prev: () => void;
  total: number;
};

type ProductsPagination = {
  startPage?: PageNumber;
  pageSize?: number;
  continuous?: boolean;
  throttleDuration?: number;
};

type ProductSearchOptions = {
  category?: CategoryType;
  excludeSoldOut?: boolean;
  tags?: string[];
  promotionId?: string;
  orderBy?: string;
  orderMethod?: string;
};

export default function useProductsKeepState(
  namespace: string = '',
  searchOptions: ProductSearchOptions = {},
  pagination: ProductsPagination = {}
): UseProducts {
  const { category = 0, tags, promotionId, excludeSoldOut, orderBy, orderMethod } = searchOptions;
  const { startPage = 1, pageSize = 8, continuous = true, throttleDuration = 600 } = pagination;
  const [currentPage, setCurrentPage] = useKeepState<PageNumber>(
    namespace + ':currentPage',
    startPage
  );
  const [products, setProducts] = useKeepState<Product[]>(namespace + ':products', []);
  const [hasNextPage, setHasNextPage] = useKeepState<boolean>(namespace + ':hasNextPage', false);
  const [total, setTotal] = useKeepState<number>(namespace + ':total', 0);

  const callProducts = useCallback(
    (
      category: CategoryType,
      page: PageNumber,
      pageSize: number,
      continuous: boolean,
      orderBy?: string,
      orderMethod?: string
    ) => {
      const categoryQuery = isAllCategoryType(category) ? {} : { category };
      getProducts({
        pageSize,
        page,
        ...categoryQuery,
        tags,
        excludeSoldOut,
        promotionId,
        orderBy,
        orderMethod,
      }).then(({ products, hasNextPage, meta }) => {
        setProducts((prev) => {
          if (continuous && page !== startPage) {
            return [...prev, ...products];
          }
          return [...products];
        });
        setHasNextPage(hasNextPage);
        setCurrentPage(page);
        setTotal(meta.totalCount);
      });
    },
    [startPage, category, orderBy, orderMethod]
  );

  const next = useCallback(
    throttle((targetPage?: number) => {
      if (targetPage) {
        callProducts(category, targetPage, pageSize, continuous, orderBy, orderMethod);
        return;
      }
      if (!hasNextPage) return;
      callProducts(category, currentPage + 1, pageSize, continuous, orderBy, orderMethod);
    }, throttleDuration),
    [
      hasNextPage,
      callProducts,
      category,
      currentPage,
      pageSize,
      continuous,
      throttleDuration,
      orderBy,
      orderMethod,
    ]
  );

  const prev = useCallback(
    throttle(() => {
      const hasPrev = currentPage - 1 > 1;
      if (!hasPrev) return;
      callProducts(category, currentPage - 1, pageSize, continuous, orderBy, orderMethod);
    }, throttleDuration),
    [currentPage, callProducts, category, pageSize, continuous, orderBy, orderMethod]
  );

  useLayoutEffect(() => {
    if (currentPage === startPage) {
      //최초 초기화 콜 무조건 실행할 경우 keepState가 무의미함
      callProducts(category, startPage, pageSize, continuous, orderBy, orderMethod);
    }
  }, [
    namespace,
    category,
    startPage,
    pageSize,
    continuous,
    callProducts,
    currentPage,
    throttleDuration,
    orderBy,
    orderMethod,
  ]);

  return {
    products,
    hasNextPage,
    page: currentPage,
    next,
    prev,
    total,
  };
}
