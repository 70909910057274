import { useNavigate } from 'react-router-dom';
import Page from '~/components/Page';
import Headline from '~/components/Headline.tsx';
import Container from '~/components/Container.tsx';
import TextField from '~/components/TextField.tsx';
import Button from '~/components/Button.tsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKupStore } from '~/services/kup/store';

export default function ShopAddressDetailPage() {
  const { deliveryAddress, setDeliveryAddress } = useKupStore((state) => ({
    deliveryAddress: state.deliveryAddress,
    setDeliveryAddress: state.setDeliveryAddress,
    setInputAddress: state.setInputAddress,
  }));
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [detail, setDetail] = useState<string>(deliveryAddress?.detail ?? '');

  const handleMoveToRequirement = async () => {
    setDeliveryAddress({
      ...deliveryAddress,
      detail,
    });
    navigate('/shop/address/requirement');
  };

  return (
    <Page
      type={'page'}
      fixedBottom={
        <Container py={'8px'} px={'20px'}>
          <Button disabled={!detail} fullWidth onClick={handleMoveToRequirement}>
            {
              i18n.t('shop-address-next') // Next
            }
          </Button>
        </Container>
      }
    >
      <Container py={'8px'}>
        <Container pt={'16px'} pb={'20px'} px={'20px'}>
          <Headline
            headline={
              i18n.t('shop-address-provideDetailedAddress') // Where should we deliver it?
            }
            description={
              i18n.t('shop-address-provideDetailAddressSub') // room number, exact hotel room reservation name for any other relevant details.
            }
            size={'large'}
          />
        </Container>
        <Container
          p={'8px'}
          px={'20px'}
          stackProps={{
            gap: '16px',
            justifyContent: 'center',
          }}
        >
          <TextField
            fullWidth
            autoFocus
            placeholder={i18n.t('shop-address-enterHere')}
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
          />
        </Container>
      </Container>
    </Page>
  );
}
