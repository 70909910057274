import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '~/components/Button';
import Container from '~/components/Container';
import FillBox from '~/components/FillBox';
import Headline from '~/components/Headline';
import Icon from '~/components/Icon';
import Page from '~/components/Page';
import Spotlight from '~/components/Spotlight';
import { useSnackBar } from '~/contexts/SnackBarContext';
import i18n from '~/i18n';
import { ICoupon } from '~/services/kup/models/Coupon';
import { getIssuableCoupons, issueCoupon } from '~kup/controllers/coupon';

export default function IssueCouponFromExternal() {
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const [issuedCouponIds, setIssuedCouponIds] = useState<string[]>([]);
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();

  const fetchIssuableCoupons = useCallback(() => {
    getIssuableCoupons({ appliedSurvey: true }).then(setCoupons);
  }, []);

  useEffect(() => {
    if (coupons.length !== 0) {
      return;
    }
    const interval = setInterval(() => fetchIssuableCoupons(), 300);
    return () => clearInterval(interval);
  }, [fetchIssuableCoupons, coupons.length]);

  const handleIssueCoupon = (couponId: string) => {
    issueCoupon(couponId).then(() => {
      setIssuedCouponIds((prev) => [...prev, couponId]);
    });
    showSnackBar({
      message: i18n.t('shop-coupon-couponsAreIssued'),
      lift: 120,
    });
  };

  return (
    <Page
      type={'modal'}
      name={i18n.t('common-thankYou')} // Thank you
      backgroundColor={'paper'}
      fixedBottomPosition={'fixed'}
      fixedBottom={
        <Container bottom background={'paper'} sx={() => ({ zIndex: 1 })}>
          <Container pb={'12px'} px={'20px'}>
            {coupons.length > issuedCouponIds.length ? (
              <Button
                fullWidth
                variant={'contained'}
                onClick={() => {
                  coupons.forEach((coupon) => handleIssueCoupon(coupon.id));
                }}
              >
                {i18n.t('shop-coupon-issueCoupons')}
              </Button>
            ) : (
              <Button fullWidth variant={'contained'} onClick={() => navigate('/shop')}>
                {i18n.t('shop-main-gotoShop')}
              </Button>
            )}
          </Container>
        </Container>
      }
    >
      <Spotlight
        textAlign={'center'}
        lead={
          <Icon
            type={'circle'}
            size={'40px'}
            width={'48px'}
            backgroundColor={'typography.tertiary'}
          >
            check
          </Icon>
        }
        headline={
          i18n.t('shop-coupon-thankYouForYourSurvey') // Thank you for your survey
        }
        description={
          i18n.t('shop-coupon-issueCouponButtonGuide') // Please click below to issue coupon
        }
        descriptionProps={{
          whiteSpace: 'pre-wrap',
        }}
        trail={
          <Container sx={{ width: '100vw' }} px={'20px'}>
            {coupons.map((coupon) => {
              const isIssued = issuedCouponIds.includes(coupon.id);
              return (
                <FillBox
                  sx={{ width: '100%' }}
                  type={'border'}
                  borderRadius={'6px'}
                  lead={
                    <Headline
                      size={'small'}
                      width={'200px'}
                      headline={`₩${coupon.benefitValue.toLocaleString()}`}
                      description={i18n.t('shop-coupon-cannotUnder', {
                        threshold: coupon.minOrderAmount.toLocaleString(),
                      })}
                    />
                  }
                  trail={
                    <Container stackProps={{ column: true }}>
                      <Button
                        fullWidth
                        disabled={isIssued}
                        size={'small'}
                        color={'fill'}
                        onClick={() => handleIssueCoupon(coupon.id)}
                      >
                        {isIssued ? i18n.t('shop-coupon-issued') : i18n.t('common-getThis')}
                      </Button>
                    </Container>
                  }
                ></FillBox>
              );
            })}
          </Container>
        }
      />
    </Page>
  );
}
