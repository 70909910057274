import { useNavigate } from 'react-router-dom';
import Page from '~/components/Page';
import Headline from '~/components/Headline.tsx';
import Container from '~/components/Container.tsx';
import Typography from '~/components/Typography.tsx';
import Button from '~/components/Button.tsx';
import FillBox from '~/components/FillBox.tsx';
import { useTranslation } from 'react-i18next';
import useKupStore from '~/services/kup/store';
import ValueList from '~/components/ValueList';
import IconButton from '~/components/IconButton';
import { Stack } from '@mui/material';

export default function ShopAddressCheckPage() {
  const { deliveryAddress } = useKupStore((state) => ({
    deliveryAddress: state.deliveryAddress,
    setDeliveryAddress: state.setDeliveryAddress,
    setInputAddress: state.setInputAddress,
  }));
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleMoveToAddress = async () => {
    navigate('/shop/address');
  };

  const handleMoveToDetail = async () => {
    navigate('/shop/address/detail');
  };

  const handleMoveToRequest = async () => {
    navigate('/shop/address/requirement');
  };

  const handleMoveToOrder = async () => {
    navigate(-4);
  };

  return (
    <Page
      type={'page'}
      fixedBottom={
        <Container py={'8px'} px={'20px'}>
          <Button fullWidth onClick={handleMoveToOrder}>
            {i18n.t('home-main-confirm')}
          </Button>
        </Container>
      }
    >
      <Container py={'8px'}>
        <Container pt={'16px'} pb={'20px'} px={'20px'}>
          <Headline
            headline={
              i18n.t('shop-address-reviewAddress') // Where should we deliver it?
            }
            size={'large'}
          />
        </Container>
        <Container
          p={'8px'}
          px={'20px'}
          stackProps={{
            gap: '16px',
            justifyContent: 'center',
          }}
        >
          <ValueList
            pairs={[
              {
                key: 'address',
                pair: [
                  i18n.t('shop-myOrder-address'), // Address
                  <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
                    {deliveryAddress?.searchText}
                    <IconButton iconProps={{ width: '20px' }} onClick={handleMoveToAddress}>
                      edit
                    </IconButton>
                  </Stack>,
                ],
              },
              {
                key: 'address_detail',
                pair: [
                  i18n.t('common-details'), // Address Detail
                  <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
                    {deliveryAddress?.detail ?? ''}
                    <IconButton iconProps={{ width: '20px' }} onClick={handleMoveToDetail}>
                      edit
                    </IconButton>
                  </Stack>,
                ],
              },
              {
                key: 'request',
                pair: [
                  i18n.t('common-requests'), // Request
                  <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
                    {deliveryAddress?.request ?? ''}
                    <IconButton iconProps={{ width: '20px' }} onClick={handleMoveToRequest}>
                      edit
                    </IconButton>
                  </Stack>,
                ],
              },
            ]}
          />
          <FillBox color={'fill'} display={'flex'} flexDirection={'column'}>
            <Typography variant={'BaseM_B'} component={'p'}>
              {i18n.t('shop-address-deliveryNotice')}
            </Typography>
            <Typography component={'p'} variant={'FootNote'} pt={'3px'} whiteSpace={'pre-wrap'}>
              • {i18n.t('shop-address-deliveryOnlySeoul')}
            </Typography>
            <Typography component={'p'} variant={'FootNote'} pt={'3px'} whiteSpace={'pre-wrap'}>
              • {i18n.t('shop-address-deliveryPlaceHotelPolicies')}
            </Typography>
            <Typography component={'p'} variant={'FootNote'} pt={'3px'} whiteSpace={'pre-wrap'}>
              • {i18n.t('shop-address-contactIfAddressIsUnclear')}
            </Typography>
            <Typography component={'p'} variant={'FootNote'} pt={'3px'} whiteSpace={'pre-wrap'}>
              • {i18n.t('shop-address-ordersMayBeCanceledIfDeliveryIsntPossible')}
            </Typography>
          </FillBox>
        </Container>
      </Container>
    </Page>
  );
}
