import {
  default as MuiButtonBase,
  ButtonBaseProps as MuiButtonBaseProps,
} from '@mui/material/ButtonBase';
import { default as MuiBox } from '@mui/material/Box';
import Typography from '~/components/Typography.tsx';
import { useNavigate } from 'react-router-dom';

export type IconButtonMenuProps = MuiButtonBaseProps & {
  imageUrl: string;
  label: string;
  to: string;
  width?: string;
  height?: string;
};

export default function IconButtonMenu(props: IconButtonMenuProps) {
  const { imageUrl, label, to, width = '56px', height = '56px', ...restProps } = props;
  const navigate = useNavigate();
  return (
    <MuiButtonBase
      sx={{
        display: 'inline-flex',
        flexDirection: 'column',
      }}
      onClick={() => navigate(to)}
      {...restProps}
    >
      <MuiBox
        sx={(theme) => ({
          p: '8px',
          mb: '4px',
          width,
          height,
          backgroundColor: theme.palette.fill.primary,
          borderRadius: '12px',
        })}
      >
        <img src={imageUrl} alt={label} />
      </MuiBox>
      <Typography variant={'FootNote_B'} color={'typography.secondary'}>
        {label}
      </Typography>
    </MuiButtonBase>
  );
}
