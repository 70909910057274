export type OtherCategoryProps = {
  width?: string;
  height?: string;
};
export default function IconOtherCategory(props: OtherCategoryProps) {
  const { width = '40px', height = '40px' } = props;
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.125" cy="20" r="2" fill="#101010" fill-opacity="0.16" />
      <circle cx="20.125" cy="20" r="2" fill="#101010" fill-opacity="0.16" />
      <circle cx="30.125" cy="20" r="2" fill="#101010" fill-opacity="0.16" />
    </svg>
  );
}
