type Expression = {
  [lang in 'ko' | 'en' | 'zh' | 'zht']: string;
};

type Expressions = {
  [key: string]: Expression;
};

const common: Expressions = {
  'common-thankYou': {
    ko: '감사합니다',
    en: 'Thank you',
    zh: '感谢',
    zht: '感謝',
  },
  'common-getThis': {
    ko: '받기',
    en: 'Get it',
    zh: '领取',
    zht: '領取',
  },
  'common-details': {
    ko: '상세',
    en: 'Details',
    zh: '详情',
    zht: '詳情',
  },
  'common-requests': {
    ko: '요청',
    en: 'Requests',
    zh: '配送备注',
    zht: '配送備註',
  },
  'common-category': {
    ko: '카테고리',
    en: 'Category',
    zh: '分类',
    zht: '分類',
  },
  'common-all': {
    ko: '전체',
    en: 'All',
    zh: '全部',
    zht: '全部',
  },
  'common-others': {
    ko: '기타',
    en: 'Others',
    zh: '其他',
    zht: '其他',
  },
};

const component: Expressions = {
  'component-chattingListItem-oneOnOneSupport': {
    ko: '1:1 고객 지원 채팅',
    en: '1:1 Live Chat',
    zh: '1:1 线上客服',
    zht: '1:1 線上客服',
  },
  'component-chattingListItem-tourChat': {
    ko: '투어 채팅',
    en: 'Tour Chat Room',
    zh: '导游聊天室',
    zht: '導遊聊天室',
  },
  'component-chattingListItem-mins': {
    ko: '분',
    en: 'mins',
    zh: '分钟',
    zht: '分鐘',
  },
  'component-chattingListItem-days': {
    ko: '일',
    en: 'days',
    zh: '天',
    zht: '天',
  },
  'component-chattingListItem-hours': {
    ko: '시간',
    en: 'hours',
    zh: '小时',
    zht: '小時',
  },
  'component-csPopup-welcomeMessage': {
    ko: '여행을 더욱 멋지게 만들어드리겠습니다.',
    en: 'We are here to make your journey unforgettable.',
    zh: '我们在这里让您的旅行更加精彩难忘。',
    zht: '我們在這裡讓您的旅行更加精彩難忘。',
  },
  'component-csPopup-sendEmail': {
    ko: '이메일 보내기',
    en: 'Send Email',
    zh: '发送邮件',
    zht: '發送郵件',
  },
  'component-imagePreviewer-longPressSave': {
    ko: '이미지를 저장하려면 길게 누르세요',
    en: 'Press and hold to save the image',
    zh: '长按保存图片',
    zht: '長按保存圖片',
  },
  'component-naverMap-disableInteraction': {
    ko: '상호작용 비활성화',
    en: 'Interaction Disabled',
    zh: '关闭互动',
    zht: '關閉互動',
  },
  'component-naverMap-enableInteraction': {
    ko: '상호작용 활성화',
    en: 'Interaction Enabled',
    zh: '开启互动',
    zht: '開啟互動',
  },
  'component-productItem-soldOut': {
    ko: '품절',
    en: 'Sold Out',
    zh: '售罄',
    zht: '售罄',
  },
  'component-productItem-best': {
    ko: '베스트',
    en: 'BEST',
    zh: '热销',
    zht: '熱銷',
  },
  'component-productItem-hotDeal': {
    ko: '🔥핫딜',
    en: '🔥HOT DEAL',
    zh: '🔥特价',
    zht: '🔥特價',
  },
  'component-promotionSection-soldOut': {
    ko: '품절',
    en: 'Sold Out',
    zh: '售罄',
    zht: '售罄',
  },
  'component-promotionSection-best': {
    ko: '베스트',
    en: 'BEST',
    zh: '热销',
    zht: '熱銷',
  },
  'component-promotionSection-hotDeal': {
    ko: '🔥핫딜',
    en: '🔥HOT DEAL',
    zh: '🔥特价',
    zht: '🔥特價',
  },
  'component-productItem-freeDelivery': {
    ko: '{{deliveryHour}}:00(KST)까지 무료 배송: {{deliveryDay}}',
    en: 'Free Delivery, arrival by {{deliveryHour}}:00(KST) {{deliveryDay}}',
    zh: '免费配送截至,{{deliveryDay}}{{deliveryHour}}:00(KST)前送达',
    zht: '免費配送截至,{{deliveryDay}}{{deliveryHour}}:00(KST)前送達',
  },
  'component-productList-moreProducts': {
    ko: '더 보기',
    en: 'See More',
    zh: '查看更多商品',
    zht: '查看更多商品',
  },
  'component-recallCs-oneOnOneSupport': {
    ko: '1:1 고객 지원',
    en: '1:1 Live Chat',
    zh: '1:! 线上客服',
    zht: '1:1 線上客服',
  },
  'component-recallCs-contactEmail': {
    ko: '정보를 찾을 수 없습니다. 이메일로 문의해 주세요.',
    en: 'Information not found. Please contact us via email',
    zh: '未能找到相关信息，请通过电子邮件联系我们。',
    zht: '未能找到相關信息，請通過電子郵件聯繫我們。',
  },
  'hook-useTimeoutCutOff-today': {
    ko: '오늘',
    en: 'today',
    zh: '今天',
    zht: '今天',
  },
  'hook-useTimeoutCutOff-tomorrow': {
    ko: '내일',
    en: 'tomorrow',
    zh: '明天',
    zht: '明天',
  },
  'component-commonFooter-termsAndConditions': {
    ko: '이용 약관',
    en: 'Terms & Conditions',
    zh: '条款与条件',
    zht: '條款與條件',
  },
  'component-commonFooter-privacyPolicy': {
    ko: '개인정보 보호정책',
    en: 'Privacy Policy',
    zh: '隐私政策',
    zht: '隱私政策',
  },
  'component-commonFooter-orderAgreements': {
    ko: '주문 동의',
    en: 'Order Terms and Conditions',
    zh: '订单协议',
    zht: '訂單協議',
  },
  'component-commonFooter-companyName': {
    ko: '회사명: 케이 투어 스토리 주식회사',
    en: 'Company Name: K Tour Story Co., Ltd.',
    zh: '公司名：K Tour Story Co., Ltd.',
    zht: '公司名：K Tour Story Co., Ltd.',
  },
  'component-commonFooter-address': {
    ko: '주소: 서울특별시 중구 수표로 12, 10층',
    en: 'Address: 10th Floor, 12 Supyo-ro, Jung-gu, Seoul, Korea',
    zh: '地址：10th Floor, 12 Supyo-ro, Jung-gu, Seoul, Korea',
    zht: '地址：10th Floor, 12 Supyo-ro, Jung-gu, Seoul, Korea',
  },
  'component-commonFooter-representative': {
    ko: '대표자: 박강현',
    en: 'Company Representative: Kang-Hyun Park',
    zh: '公司代表：Kang-Hyun Park',
    zht: '公司代表：Kang-Hyun Park',
  },
  'component-commonFooter-businessRegistrationNumber': {
    ko: '사업자 등록번호: 597-81-00842',
    en: 'Business Registration Number: 597-81-00842',
    zh: '营业执照号：597-81-00842',
    zht: '營業執照號：597-81-00842',
  },
  'component-commonFooter-onlineSalesRegistrationNumber': {
    ko: '통신판매업 신고번호: 2023-서울중구-1420',
    en: 'Online Sales Registration Number: 2023-서울중구-1420',
    zh: '网上销售注册号：2023-서울중구-1420',
    zht: '網上銷售註冊號：2023-서울중구-1420',
  },
  'component-commonFooter-customerServiceForTour': {
    ko: '투어 고객 서비스:',
    en: 'Tourist Support Service:',
    zh: '游客资源服务：',
    zht: '遊客資源服務：',
  },
  'component-commonFooter-customerService': {
    ko: '고객 서비스:',
    en: 'Customer Service:',
    zh: '客户服务：',
    zht: '客戶服務：',
  },
  'layout-mainLayout-home': {
    ko: '홈',
    en: 'Home',
    zh: '首页',
    zht: '首頁',
  },
  'layout-mainLayout-shop': {
    ko: '쇼핑',
    en: 'Shop',
    zh: '购物',
    zht: '購物',
  },
  'layout-mainLayout-discovery': {
    ko: '탐색',
    en: 'Discovery',
    zh: '探索',
    zht: '探索',
  },
  'layout-mainLayout-message': {
    ko: '메시지',
    en: 'Message',
    zh: '信息',
    zht: '信息',
  },
  'common-error-somethingWentWrong': {
    ko: '문제가 발생했습니다 :(',
    en: 'Something went wrong :(',
    zh: '系统出现问题 :(',
    zht: '系統出現問題 :(',
  },
  'common-error-unexpectedError': {
    ko: '예기치 못한 오류가 발생했습니다. \n도움이 필요하면 연락해 주세요.',
    en: 'An unexpected error has occurred. \nPlease contact us if you need assistance.',
    zh: '系统出现错误，\n如有需要，请联系我们',
    zht: '系統出現錯誤，\n如有需要，請聯繫我們。',
  },
  'common-error-contact': {
    ko: '연락하기',
    en: 'Contact',
    zh: '联系我们',
    zht: '聯繫我們',
  },
  'common-help-doYouNeedHelp': {
    ko: '도움이 필요하신가요?',
    en: 'Do you need any help?',
    zh: '需要帮助吗？',
    zht: '需要幫助嗎？',
  },
};
const homeMain: Expressions = {
  'home-main-hiUser': {
    ko: '안녕하세요, {{name}}님',
    en: 'Hi, {{name}}',
    zh: '嗨, 您好, {{name}}',
    zht: '嗨, 您好 {{name}}',
  },
  'home-main-joinTourChat': {
    ko: '아래 투어 채팅에 참여하세요!',
    en: 'Join the chat below to talk to the tour guide!',
    zh: '点击下方与导游联系！',
    zht: '點擊下方與導遊聯繫！',
  },
  'home-main-cantWait': {
    ko: '곧 만나요!',
    en: "We can't wait to meet you!",
    zh: '很期待见到您！',
    zht: '很期待見到您！',
  },
  'home-main-hopeEnjoyedTour': {
    ko: '투어가 즐거우셨길 바라요!',
    en: 'We hope you enjoyed the tour!',
    zh: '希望您享受这次旅程！',
    zht: '希望您享受這次旅程！',
  },
  'home-main-enjoyTour': {
    ko: '즐거운 투어가 되길 바라요!',
    en: 'Enjoy your tour!',
    zh: '祝你旅途愉快！',
    zht: '祝你旅途愉快！',
  },
  'home-main-myBooking': {
    ko: '내 예약',
    en: 'My Booking',
    zh: '我的预订',
    zht: '我的預訂',
  },
  'home-main-grabBestPrice': {
    ko: '최저가로 구매하고 무료 배송 받으세요',
    en: 'Buy at the lowest price and enjoy free shipping!',
    zh: '我们保证最低价格，并为您提供免费的配送服务。',
    zht: '我們保證最低價格，並為您提供免費的配送服務。',
  },
  'home-main-bestServiceVibe': {
    ko: '최저가, 최고의 서비스, 진짜 한국 감성',
    en: 'Lowest prices, exceptional service, and authentic Korean style.',
    zh: '最低价格，最优质的服务，最地道的韩国风情',
    zht: '最低價格，最優質的服務，最地道的韓國風情',
  },
  'home-main-blazingFastDelivery': {
    ko: '초고속 무료 배송!\n지금 주문하면 16시간 이내에 받아보세요',
    en: 'Blazing Fast FREE DELIVERY!\nOrder now and get it within 16 hours',
    zh: '超快速免费配送！\n现在下单,16小时内送达',
    zht: '超快速免費配送！\n現在下單,16小時內送達',
  },
  'home-main-checkBus': {
    ko: '버스 확인하기!',
    en: 'Check your bus details!',
    zh: '查看您的班车信息！',
    zht: '查看您的班車資訊！',
  },
  'home-main-checkPickupPlace': {
    ko: '픽업 장소 확인하기!',
    en: 'Check your Pickup Point!',
    zh: '查看您的接送地点！',
    zht: '查看您的接送地點！',
  },
  'home-main-busDepartsOnTime': {
    ko: '버스는 정시에 출발합니다. 5분 일찍 도착하시는 것을 권장합니다.',
    en: 'Bus departs on time. It is recommended to arrive 5 minutes early.',
    zh: '巴士准时出发。建议提前5分钟到达。',
    zht: '巴士準時出發。建議提前5分鐘到達。',
  },
  'home-main-departureTime': {
    ko: '{{departureTime}} 출발',
    en: 'Departure at {{departureTime}}',
    zh: '{{departureTime}}出发',
    zht: '{{departureTime}}出發',
  },
  'home-main-findOnGoogleMap': {
    ko: '구글 지도에서 찾기',
    en: 'Find On Google Map',
    zh: '在谷歌地图上查找',
    zht: '在谷歌地圖上查找',
  },
  'home-main-moreTagProducts': {
    ko: '더 많은 {{tagName}} 상품',
    en: 'See More {{tagName}} Products',
    zh: '更多{{tagName}}商品',
    zht: '更多{{tagName}}商品',
  },
  'home-main-confirm': {
    ko: '확인',
    en: 'Confirm',
    zh: '确认',
    zht: '確認',
  },
  'home-main-checkDifferentReservation': {
    ko: '다른 예약 확인하기',
    en: 'Check Other Reservations',
    zh: '查看其他预订',
    zht: '查看其他預訂',
  },
  'home-main-reservationByClient': {
    ko: '{{clientName}}님이 예약한 내역',
    en: 'The reservation made by {{clientName}}',
    zh: '{{clientName}}的预订',
    zht: '{{clientName}}的預訂',
  },
  'home-main-date': {
    ko: '날짜',
    en: 'Date',
    zh: '日期',
    zht: '日期',
  },
  'home-main-tour': {
    ko: '투어',
    en: 'Tour',
    zh: '一日游',
    zht: '一日遊',
  },
  'home-main-name': {
    ko: '이름',
    en: 'Name',
    zh: '姓名',
    zht: '姓名',
  },
  'home-main-travelers': {
    ko: '여행자',
    en: 'Travelers',
    zh: '旅行者',
    zht: '旅行者',
  },
  'home-main-adult': {
    ko: '성인',
    en: 'Adult',
    zh: '成人',
    zht: '成人',
  },
  'home-main-kid': {
    ko: '어린이',
    en: 'Kid',
    zh: '儿童',
    zht: '兒童',
  },
  'home-main-infant': {
    ko: '유아',
    en: 'Infant',
    zh: '婴儿',
    zht: '嬰兒',
  },
  'home-main-pickup': {
    ko: '픽업',
    en: 'Pickup',
    zh: '接送',
    zht: '接送',
  },
  'home-main-email': {
    ko: '이메일',
    en: 'Email',
    zh: '邮箱',
    zht: '郵箱',
  },
  'home-main-platform': {
    ko: '플랫폼',
    en: 'Platform',
    zh: '平台',
    zht: '平台',
  },
  'home-main-id': {
    ko: '아이디',
    en: 'ID',
    zh: '账号',
    zht: '帳號',
  },

  'home-main-tourInformation': {
    ko: '투어 정보',
    en: 'Tour Information',
    zh: '一日游资讯',
    zht: '一日遊資訊',
  },
  'home-main-coupon-exclusive': {
    ko: '특어 게스트를 위한 특별 쿠폰',
    en: 'Coupons exclusively for you',
    zh: '一日游游客专属优惠',
    zht: '一日遊遊客專屬優惠',
  },
  'home-main-coupon-offer-ends': {
    ko: '제공 마감까지 {{time}}!',
    en: 'Offer ends in {{time}}!',
    zh: '将在 {{time}} 后结束！',
    zht: '將在 {{time}} 後結束！',
  },
  'home-main-coupon-available-only': {
    ko: '오직 이 쿠폰만 사용 가능합니다.',
    en: 'Available only on your tour day! Don’t miss out!',
    zh: '仅限游览当天享受！千万别错过！',
    zht: '僅限遊覽當天享受！千萬別錯過！',
  },
  'home-main-coupon-enjoy-lowest': {
    ko: '최저가 보장! 추가 할인 포함!',
    en: 'Enjoy guaranteed lowest prices plus additional discounts!',
    zh: '最低价保证，再享额外优惠！',
    zht: '最低價保證，再享額外優惠！',
  },
  'home-main-coupon-valid-on': {
    ko: '{{amount}} KRW+ 이상 사용 가능',
    en: 'For purchases above {{amount}} KRW.',
    zh: '只适用于{{amount}} KRW以上订单',
    zht: '只適用於{{amount}} KRW以上訂單',
  },
  'home-main-coupon-no-purchase-limit': {
    ko: '구매 제한 없음',
    en: 'No purchase limits',
    zh: '无购买限制',
    zht: '無購買限制',
  },
  'home-main-coupon-get': {
    ko: 'Get your coupon and use it now!',
    en: 'Get your coupon and use it now!',
    zh: '快来领取优惠券并立即使用！',
    zht: '快來領取優惠券並立即使用！',
  },
  'home-main-coupon-valid-24h': {
    ko: '24시간 내에 사용 가능',
    en: 'Available for 24 hours after issued.',
    zh: '优惠券自领取日起24小时内有效',
    zht: '優惠券自領取日起24小時內有效',
  },
};
const messageChat: Expressions = {
  'message-chat-replyToSender': {
    ko: '{replyTo.sender.name}님에게 답장하기',
    en: 'Reply to {{senderName}}',
    zh: '回复{{senderName}}',
    zht: '回覆{{senderName}}',
  },
  'message-chat-typeMessage': {
    ko: '여기에 메시지를 입력하세요',
    en: 'Type message here',
    zh: '在此输入消息',
    zht: '在此輸入消息',
  },
  'message-chat-stayUpdatedTourChat': {
    ko: '투어 채팅에서 업데이트 받기',
    en: 'Stay updated via Tour Chat Room',
    zh: '在导游聊天室获取最新信息',
    zht: '在導遊聊天室獲取最新資訊',
  },
  'message-chat-turnOn': {
    ko: '켜기',
    en: 'Turn On',
    zh: '开启',
    zht: '開啟',
  },
  'message-chat-skip': {
    ko: '건너뛰기',
    en: 'Skip',
    zh: '跳过',
    zht: '跳過',
  },
  'message-chat-realtimeUpdatesTourChat': {
    ko: '투어 채팅의 실시간 업데이트를 받아보세요. 알림 설정은 언제든지 변경할 수 있습니다.',
    en: 'Get real-time updates from the Tour Chat Room. You can change your notification settings anytime.',
    zh: '通过导游聊天室接收实时更新。您可以随时更改通知设置。',
    zht: '透過導遊聊天室接收即時更新。您可以隨時更改通知設定。',
  },
  'message-chat-skipChatNotification': {
    ko: '건너뛰면 채팅 알림 요청이 6시간 동안 숨겨집니다.',
    en: 'By skipping, chat notifications will be paused for the next 6 hours',
    zh: '跳过后，聊天通知将会暂停6小时。',
    zht: '跳過後，聊天通知將會暫停6小時。',
  },
  'message-chat-previouslyBlockedNotifications': {
    ko: '이 기기에서 이전에 알림을 차단하셨나요?',
    en: 'Have you turned off notifications on this device before?',
    zh: '请问您之前是否有关闭过此设备的通知？',
    zht: '請問您之前是否有關閉過此設備的通知？',
  },
  'message-chat-changeSettingsToEnableNotifications': {
    ko: '이전에 알림을 차단했다면 브라우저 또는 앱 설정을 변경해야 합니다.',
    en: 'If you have blocked notifications previously, you will need to change the settings in your browser or app.',
    zh: '如果您之前已关闭通知，请在浏览器或应用程序设置中进行调整。',
    zht: '如果您之前已关闭通知，請在瀏覽器或應用程式設定中進行調整。',
  },
  'message-chat-iosNotificationInstructions': {
    ko: 'iOS의 경우 설정 > 알림 > Kup > 알림을 활성화하기.',
    en: 'For iOS users, go to Settings > Notifications > Kup > Enable notifications.',
    zh: 'iOS用户, 前往设置 > 通知 > Kup > 启用通知。',
    zht: 'iOS用戶, 前往設置 > 通知 > Kup > 啟用通知。',
  },
  'message-chat-androidNotificationInstructions': {
    ko: 'Android의 경우 설정 > 앱 및 알림 > Kup > 알림에서 활성화하세요.',
    en: 'For Android users, go to Settings > Apps & notifications > Kup > Enable Notifications.',
    zh: 'Android用户 ,前往设置 > 应用和通知 > Kup > 启用通知。',
    zht: 'Android用戶, 前往設置 > 應用和通知 > Kup > 啟用通知。',
  },
  'message-chat-stayUpdatedImportantInfo': {
    ko: '중요한 정보로 업데이트 받기',
    en: 'Stay Updated on Important Information',
    zh: '随时掌握重要信息。',
    zht: '隨時掌握重要資訊。',
  },
  'message-chat-realtimeUpdatesInfo': {
    ko: '실시간 업데이트를 받아보세요. 알림 설정은 언제든지 변경할 수 있습니다.',
    en: 'Stay informed with real-time updates. Notification settings can be changed at any time. ',
    zh: '随时接收实时更新，您可以随时更改通知设置。',
    zht: '隨時接收即時更新，您可以隨時更改通知設定。',
  },
  'message-chat-skipNotificationFor12Hours': {
    ko: '건너뛰면 채팅 알림 요청이 12시간 동안 숨겨집니다.',
    en: 'By skipping, chat notifications will be paused for the next 12 hours. ',
    zh: '跳过后，聊天通知将会暂停12小时。',
    zht: '跳過後，聊天通知將會暫停12小時。',
  },
  'message-chat-changeSettingsForNotifications': {
    ko: '이전에 알림을 차단했다면 브라우저 또는 앱 설정을 변경해야 합니다.',
    en: 'If you have blocked notifications previously, you will need to change the settings in your browser or app.',
    zh: '如果您之前已关闭通知，请在浏览器或应用程序设置中进行调整。',
    zht: '如果您之前已关闭通知，請在瀏覽器或應用程式設定中進行調整。',
  },
  'message-chat-beforeTurnOffNotifications': {
    ko: '알림을 끄기 전에',
    en: 'Before You Turn Off Notifications',
    zh: '关闭通知之前',
    zht: '關閉通知之前',
  },
  'message-chat-considerStayingUpdated': {
    ko: '최신 알림을 유지하세요. 중요한 정보를 놓칠 수 있습니다.',
    en: 'Stay updated with the latest notifications to avoid missing important information.',
    zh: '随时检查最新资讯，以免错过重要内容。',
    zht: '隨時檢查最新資訊，以免錯過重要內容。',
  },
  'message-chat-wantToBlockForDevice': {
    ko: '이 기기에 대해 차단하시겠습니까?',
    en: 'Would you like to block notifications on this device?',
    zh: '您是否要在此设备上关闭通知？',
    zht: '您是否要在此設備上關閉通知？',
  },
  'message-chat-browserSettingsToDisable': {
    ko: '알림을 차단하려면 브라우저 설정에서 변경할 수 있습니다.',
    en: 'You can change your browser settings to block notifications.',
    zh: '您可以在浏览器设置中更改以关闭通知。',
    zht: '您可以在瀏覽器設定中更改以關閉通知。',
  },
  'message-chat-installedInstructions': {
    ko: '설치되었나요?',
    en: 'Has it been installed?',
    zh: '是否已安装完成？',
    zht: '是否已安裝完成？',
  },
  'message-chat-iosNotificationInstructionsInstalled': {
    ko: 'iOS의 경우 설정 > 알림 > Kup > 알림을 활성화하기.',
    en: 'For iOS users, go to Settings > Notifications > Kup > Enable notifications.',
    zh: 'iOS用户, 前往设置 > 通知 > Kup > 启用通知。',
    zht: 'iOS用戶, 前往設置 > 通知 > Kup > 啟用通知。',
  },
  'message-chat-androidNotificationInstructionsInstalled': {
    ko: 'Android의 경우 설정 > 앱 및 알림 > Kup > 알림에서 활성화하세요.',
    en: 'For Android users, go to Settings > Apps & notifications > Kup > Enable Notifications.',
    zh: 'Android用户 ,前往设置 > 应用和通知 > Kup > 启用通知。',
    zht: 'Android用戶, 前往設置 > 應用和通知 > Kup > 啟用通知。',
  },
  'message-chat-close': {
    ko: '닫기',
    en: 'Close',
    zh: '关闭',
    zht: '關閉',
  },
  'message-chat-keepTurnOn': {
    ko: '계속 켜두기',
    en: 'Keep Turned On',
    zh: '保持开启',
    zht: '保持開啟',
  },
  'message-chat-turnOff': {
    ko: '끄기',
    en: 'Turn Off',
    zh: '关闭',
    zht: '關閉',
  },
};
const messageMain: Expressions = {
  'message-main-needHelpWithTour': {
    ko: '투어에 도움이 필요하신가요?',
    en: 'Do you need help with the tour?',
    zh: '您在导览过程中或对导览有任何需要协助的地方吗？',
    zht: '您在導覽過程中或對導覽有任何需要協助的地方嗎？',
  },
  'message-main-needHelpWithShopping': {
    ko: '쇼핑에 도움이 필요하신가요?',
    en: 'Do you need help with shopping?',
    zh: '您在购物时需要帮助吗？',
    zht: '您在購物時需要幫助嗎？',
  },
  'message-main-notification': {
    ko: '알림',
    en: 'Notification',
    zh: '通知',
    zht: '通知',
  },
  'message-main-chat': {
    ko: '채팅',
    en: 'Chat',
    zh: '聊天',
    zht: '聊天',
  },
  'message-main-noNotifications': {
    ko: '현재 알림이 없습니다',
    en: 'There are no notifications at the moment',
    zh: '暂无新通知',
    zht: '暫無新通知',
  },
};
const discoveryContent: Expressions = {
  'discovery-content-bestPriceGuarantee': {
    ko: '최저가 보장으로 구매하세요!',
    en: 'Buy at the Guaranteed Best Price!',
    zh: '保证全网最低价！',
    zht: '保證全網最低價！',
  },

  'discovery-content-checkShopNow': {
    ko: '지금 쇼핑 확인하기!',
    en: 'Check the Store Now!',
    zh: '立即查看商店！',
    zht: '立即查看商店！',
  },

  'discovery-content-readMore': {
    ko: '더 보기',
    en: 'Read More',
    zh: '阅读更多',
    zht: '閱讀更多',
  },
};
const discoveryMain: Expressions = {
  'discovery-main-thankYouForPatience': {
    ko: '기다려 주셔서 감사합니다',
    en: 'Thank You For Your Patience',
    zh: '感谢您的耐心等待',
    zht: '感謝您的耐心等待',
  },
  'discovery-main-contentUnderPreparation': {
    ko: '현재 콘텐츠 준비 중입니다.\n이해해 주셔서 감사합니다.',
    en: 'This content is currently under preparation.\nWe appreciate your understanding.',
    zh: '该内容正在准备中。\n感谢您的理解。',
    zht: '該內容正在準備中。\n感謝您的理解。',
  },
};
const discoveryPlace: Expressions = {
  'discovery-place-findOnGoogleMap': {
    ko: '구글 지도에서 찾기',
    en: 'Find on Google Map',
    zh: '在谷歌地图上查找',
    zht: '在谷歌地圖上查找',
  },
  'discovery-place-information': {
    ko: '정보',
    en: 'Information',
    zh: '信息',
    zht: '資訊',
  },
  'discovery-place-location': {
    ko: '위치',
    en: 'Location',
    zh: '位置',
    zht: '位置',
  },
  'discovery-place-recommendations': {
    ko: '추천',
    en: 'Recommendations',
    zh: '推荐',
    zht: '推薦',
  },
};
const reservationMain: Expressions = {
  'reservation-main-cannotVerifyReservation': {
    ko: '예약을 확인할 수 없습니다',
    en: 'Unable to verify the reservation',
    zh: '无法验证您的预订',
    zht: '無法驗證您的預訂',
  },
  'reservation-main-agreeAndStart': {
    ko: '동의하고 시작하기',
    en: 'Agree and Begin',
    zh: '同意并开始',
    zht: '同意並開始',
  },
  'reservation-main-noServiceUsage': {
    ko: '아니요, 이 서비스를 이용하지 않겠습니다',
    en: 'No, I choose not to use this service.',
    zh: '不, 我不想使用此服务',
    zht: '不, 我不想使用此服務',
  },
  'reservation-main-valueTrust': {
    ko: '귀하의 신뢰를 소중히 여깁니다. 계속하려면 아래 약관을 검토하고 동의해 주세요.',
    en: 'We value your trust. Kindly review and agree to the terms below to proceed.',
    zh: '感谢您的信任。请阅读并同意以下条款以继续。',
    zht: '感謝您的信任。請閱讀並同意以下條款以繼續。',
  },
  'reservation-main-checkAgreementsToProceed': {
    ko: '계속하려면 아래 약관을 확인해 주세요',
    en: 'Please check the terms and conditions below to proceed',
    zh: '请检查以下协议以继续',
    zht: '請檢查以下協議以繼續',
  },
  'reservation-main-essentialInfoUsage': {
    ko: '당사는 서비스 제공을 위해 필수 정보만 수집 및 사용합니다',
    en: 'We collect and use only the necessary information to provide our services.',
    zh: '我们只收集和使用为提供服务所必需的信息。',
    zht: '我們只收集和使用為提供服務所必需的信息。',
  },
  'reservation-main-privacyPolicies': {
    ko: '개인정보 처리방침',
    en: 'Privacy Policies',
    zh: '隐私政策',
    zht: '隱私政策',
  },
  'reservation-main-termsConditions': {
    ko: '이용 약관',
    en: 'Terms & Conditions',
    zh: '条款和条件',
    zht: '條款和條件',
  },
  'reservation-main-servicesProvidedTermsApply': {
    ko: '저희는 투어, 정보 및 상거래 서비스를 제공합니다. 약관이 적용됩니다.',
    en: 'We offer tour, information, and commerce services. Terms and conditions apply.',
    zh: '我们提供旅游导览、信息和商业服务。适用条款。',
    zht: '我們提供旅遊導覽、信息和商業服務。適用條款。',
  },
  'reservation-main-cannotCheckReservation': {
    ko: '예약을 확인할 수 없습니다.',
    en: 'Unable to check the reservation',
    zh: '无法查看您的预订。',
    zht: '無法查看您的預訂。',
  },
  'reservation-main-reservationId': {
    ko: '예약 ID',
    en: 'Your Reservation ID',
    zh: '您的预订ID',
    zht: '您的預訂ID',
  },
  'reservation-main-hiLetMeKnow': {
    ko: '안녕하세요, 알려주세요',
    en: 'Hi, Please let me know',
    zh: '您好,请告诉我',
    zht: '您好,請告訴我',
  },
  'reservation-main-needHelp': {
    ko: '도움이 필요하신가요?',
    en: 'Do you need help?',
    zh: '需要帮助吗？',
    zht: '需要幫助嗎？',
  },
  'reservation-main-greetingGetStarted': {
    ko: '안녕하세요, {{name}}님!\n시작해볼까요?',
    en: 'Hi {{name}},\nshall we get started!',
    zh: '嗨,{{name}},\n准备好开始了吗？',
    zht: '嗨,{{name}},\n準備好開始了嗎？',
  },
  'reservation-main-next': {
    ko: '다음',
    en: 'Next',
    zh: '下一步',
    zht: '下一步',
  },
  'reservation-main-enterReservationCode': {
    ko: '예약 코드를 입력해 주세요.',
    en: 'Please enter your reservation code.',
    zh: '请输入您的预订代码。',
    zht: '請輸入您的預訂代碼。',
  },
};
const reservationConfirm: Expressions = {
  'reservation-confirm-yesCorrect': {
    ko: '네, 맞아요!',
    en: "Yes, that's correct!",
    zh: '是的,没错！',
    zht: '是的,沒錯！',
  },
  'reservation-confirm-noIncorrect': {
    ko: '아니요, 그렇지 않습니다',
    en: "No, that's not correct!",
    zh: '不, 不是的',
    zht: '不, 不是的',
  },
  'reservation-confirm-confirmReservationByClient': {
    ko: '이 예약은\n{{clientName}}님이 하신 것인가요?',
    en: 'Is this a reservation made by\n{{clientName}}?',
    zh: '这是由{{clientName}}预订的吗？',
    zht: '這是由{{clientName}}預訂的嗎？',
  },
  'reservation-confirm-checkDetailsBelow': {
    ko: '아래 세부 사항을 확인해 주세요',
    en: 'Please check the details below',
    zh: '请检查以下详情',
    zht: '請檢查以下详情',
  },
  'reservation-confirm-reservationInfo': {
    ko: '예약 정보',
    en: 'Reservation Info',
    zh: '预订信息',
    zht: '預訂信息',
  },
  'reservation-confirm-date': {
    ko: '날짜',
    en: 'Date',
    zh: '日期',
    zht: '日期',
  },
  'reservation-confirm-tour': {
    ko: '투어',
    en: 'Tour',
    zh: '一日游',
    zht: '一日遊',
  },
  'reservation-confirm-travelers': {
    ko: '여행자',
    en: 'Travelers',
    zh: '旅行者',
    zht: '旅行者',
  },
  'reservation-confirm-adult': {
    ko: '성인',
    en: 'Adult',
    zh: '成人',
    zht: '成人',
  },
  'reservation-confirm-kid': {
    ko: '어린이',
    en: 'Kid',
    zh: '儿童',
    zht: '兒童',
  },
  'reservation-confirm-infant': {
    ko: '유아',
    en: 'Infant',
    zh: '婴儿',
    zht: '嬰兒',
  },
  'reservation-confirm-platform': {
    ko: '플랫폼',
    en: 'Platform',
    zh: '平台',
    zht: '平台',
  },
  'reservation-confirm-id': {
    ko: '아이디',
    en: 'ID',
    zh: '账号',
    zht: '帳號',
  },
};
const reservationName: Expressions = {
  'reservation-name-confirm': {
    ko: '확인',
    en: 'Confirm',
    zh: '确认',
    zht: '確認',
  },
  'reservation-name-welcomeMessage': {
    ko: '환영합니다!\n어떻게 불러드릴까요?',
    en: 'Welcome!\nHow should I address you?',
    zh: '欢迎！\n我应该怎么称呼您？',
    zht: '歡迎！\n我應該怎麼稱呼您？',
  },
  'reservation-name-nameUsedToIdentify': {
    ko: '이 이름은 다른 그룹 멤버와 구분하는 데 사용됩니다.',
    en: 'This name will be used to distinguish you from other group members.',
    zh: '此名称将用于将您与其他组成员区分开来。',
    zht: '此名稱將用於將您與其他組成員區分開來。',
  },
  'reservation-name-myNameIs': {
    ko: '제 이름은...',
    en: 'My name is...',
    zh: '我的名字是...',
    zht: '我的名字是...',
  },
  'reservation-name-pleaseUseIdentifiableName': {
    ko: '식별 가능한 이름을 사용해 주세요.',
    en: 'Please use a distinguishable name.',
    zh: '请使用一个容易识别的名字。',
    zht: '請使用一個容易識別的名字。',
  },
};
const shopAddress: Expressions = {
  'shop-address-addressNotFound': {
    ko: '주소를 찾을 수 없습니다. 확인 후 다시 시도해 주세요.',
    en: 'Address not found. Please verify and try again.',
    zh: '未找到地址。请核实后重试。',
    zht: '未找到地址。請核實後重試。',
  },
  'shop-address-rightPosition': {
    ko: '이 위치가 맞습니다!',
    en: 'This is the right position!',
    zh: '这是正确的位置！',
    zht: '這是正確的位置！',
  },
  'shop-address-addressDetailsYouCan': {
    ko:
      '가능한 제공 가능한 주소 정보를 모두 입력해주세요.\n' +
      '주소 확인이 필요한 경우, 저희가 연락을 드리겠습니다.',
    en:
      'Please provide all the address details you can.\n' +
      'If address verification is needed, we will contact you.',
    zh: '请尽可能提供完整的地址信息。\n' + '如需确认地址，我们会与您联系。',
    zht: '請盡可能提供完整的地址資訊。\n' + '如需確認地址，我們會與您聯繫。',
  },
  'shop-address-enterAddressHere': {
    ko: '주소를 입력하세요 (서울에서만 사용 가능)',
    en: 'Enter Address Here (Only Available in Seoul)',
    zh: '在此输入地址（仅限于首尔地区）',
    zht: '在此輸入地址（僅限于首爾地區）',
  },
  'shop-address-findAddressByHotel': {
    ko: '호텔/건물 이름으로 주소 찾기',
    en: 'Find address by hotel or building name',
    zh: '按酒店, 建筑名称查找地址',
    zht: '按酒店, 建築名稱查找地址',
  },
  'shop-address-checkAgain': {
    ko: '다시 확인',
    en: 'Check again',
    zh: '重新检查',
    zht: '重新檢查',
  },
  'shop-address-enterDetailedAddress': {
    ko: '상세 주소를 입력해 주세요',
    en: 'Please enter the detailed address',
    zh: '请输入详细地址',
    zht: '請輸入詳細地址',
  },
  'shop-address-enterHere': {
    ko: '여기에 입력 (예: 방 번호, 층수...)',
    en: 'Enter Here (ex Room Number, floor...)',
    zh: '在此输入（例如房间号,楼层...）',
    zht: '在此輸入（例如房間號,樓層...）',
  },
  'shop-address-next': {
    ko: '다음',
    en: 'Next',
    zh: '下一步',
    zht: '下一步',
  },
  'shop-address-instructionsOrRequests': {
    ko: '지시 사항이나 요청이 있으신가요?',
    en: 'Any instructions or requests?',
    zh: '有任何指示或需求吗？',
    zht: '有任何指示或需求嗎？',
  },
  'shop-address-lobbyPickupRequired': {
    ko: '호텔 정책에 따라 로비 또는 입구 픽업이 필요할 수 있습니다.',
    en: 'Due to hotel policy, pickup may be required at the lobby or entrance.',
    zh: '根据酒店政策，可能需要在大堂或入口处领取。',
    zht: '根據酒店政策，可能需要在大廳或入口處領取。',
  },
  'shop-address-airbnb': {
    ko: 'Airbnb의 경우, 필요 시 공동현관 비밀번호를 입력해주세요.',
    en: 'For Airbnb, please provide the main entrance passcode if required.',
    zh: '对于Airbnb，如需提供，请填写大门密码。',
    zht: '對於Airbnb，如需提供，請填寫大門密碼。',
  },
  'shop-address-deliveryInstructions': {
    ko: '배송 지시 사항',
    en: 'Delivery instructions',
    zh: '配送指示',
    zht: '配送指示',
  },
  'shop-address-confirm': {
    ko: '확인',
    en: 'Confirm',
    zh: '确认',
    zht: '確認',
  },
  'shop-address-rightPlace': {
    ko: '이곳이 맞습니까?',
    en: 'Is this the right place?',
    zh: '这是正确的地点吗？',
    zht: '這是正確的地點嗎？',
  },
  'shop-address-detailAddressInfo': {
    ko: '상세 주소 정보 (예: 방 번호, 층수...)',
    en: 'Detailed Address Info (ex Room Number, floor...)',
    zh: '详细地址信息（如房间号,楼层...）',
    zht: '詳細地址信息（如房間號,樓層...）',
  },
  'shop-address-inPersonPickupMayBeRequired': {
    ko: '호텔 정책이나 배송 사정에 따라 로비에서 직접 수령이 필요할 수 있습니다.',
    en: 'Due to hotel policy, pickup may be required at the lobby or entrance.',
    zh: '根据酒店政策，可能需要在大堂或入口处领取。',
    zht: '根據酒店政策，可能需要在大廳或入口處領取。',
  },
  'shop-address-unableToVerifyAddress': {
    ko: '주소를 확인할 수 없습니다',
    en: 'Unable to verify the address',
    zh: '无法验证地址',
    zht: '無法驗證地址',
  },
  'shop-address-whereToDeliver': {
    ko: '어디로 배송할까요?',
    en: 'Where would you like it delivered?',
    zh: '我们应该送到哪里？',
    zht: '我們應該送到哪裡？',
  },
  'shop-address-example': {
    ko: '예시',
    en: 'Example',
    zh: '例子',
    zht: '例子',
  },
  'shop-address-poweredByGoogleMaps': {
    ko: 'Google 지도에서 제공됩니다.\n유효한 주소는 Google 지도 또는 아래 검색 기능을 통해 찾을 수 있습니다.',
    en: 'This is powered by Google Maps.\nA valid address can be found using Google Maps or the search function below.',
    zh: '本服务由 Google 提供支持。\n您可以通过 Google 地图或下方的搜索功能查找有效地址。',
    zht: '本服務由 Google 提供支持。\n您可以通過 Google 地圖或下方的搜索功能查找有效地址。',
  },
  'shop-address-findAddressByHotelName': {
    ko: '호텔 또는 건물 이름으로 주소 검색',
    en: 'Search address by hotel or building name',
    zh: '通过酒店或建筑名称搜索地址',
    zht: '透過酒店或建築名稱搜尋地址',
  },
  'shop-address-seoulDeliveryOnly': {
    ko: '현재 서울 지역에서만 배송 가능합니다',
    en: 'Delivery is currently available only in the Seoul area.',
    zh: '目前仅限于首尔地区配送',
    zht: '目前僅限于首爾地區配送',
  },
  'shop-address-hotelNameOrStayPlace': {
    ko: '호텔 이름 또는 숙소 이름',
    en: 'Hotel name or accommodation name.',
    zh: '酒店名称或您的住宿地点',
    zht: '酒店名稱或您的住宿地點',
  },
  'shop-address-hotelNameAddress': {
    ko: '호텔 이름 또는 숙소 이름',
    en: 'Hotel name or detailed address.',
    zh: '酒店名称或详细地址',
    zht: '旅館名稱或詳細地址',
  },
  'shop-address-givenSpecificAddress': {
    ko: '제공된 특정 주소',
    en: 'The specific address provided',
    zh: '提供的具体地址',
    zht: '提供的具體地址',
  },
  'shop-address-provideDetailedAddress': {
    ko: '상세 주소를 입력해주세요.',
    en: 'Please provide the detailed address for your delivery.',
    zh: '请提供详细的地址以便配送。',
    zht: '請提供詳細的地址以便配送。',
  },
  'shop-address-provideDetailAddressSub': {
    ko: '방 번호, 호텔 방 예약 이름 등 관련 세부 정보를 입력해주세요.',
    en: 'Please provide the detailed address (room number, floor, hotel reservation name, etc.).',
    zh: '请提供详细的地址（ 房号、楼层、酒店预订姓名等 )',
    zht: '請提供詳細的地址 ( 房號、樓層、酒店預訂姓名等 )',
  },
  'shop-address-provideDeliveryInstructions': {
    ko: '배송과 관련된 지시 사항이나 요청이 있으신가요?',
    en: 'Any delivery instructions or requests?',
    zh: '请问有与配送相关的指示或要求吗 ?',
    zht: '請問有與配送相關的指示或要求嗎 ?',
  },
  'shop-address-provideDeliveryInstructionsPlaceholder': {
    ko: '배송에 대한 요청사항',
    en: 'Instructions and requests for delivery (Hotel policy, time, etc)',
    zh: '配送备注与要求 (酒店政策, 时间等)',
    zht: '配送備註與要求 (酒店政策 時間等)',
  },
  'shop-address-reviewAddress': {
    ko: '주소 정보를 검토해주세요.',
    en: 'Please review the address information provided.',
    zh: '请检查所提供的地址信息',
    zht: '請檢查所提供的地址信息',
  },
  'shop-address-deliveryNotice': {
    ko: '배송 정책',
    en: 'Delivery Noitce',
    zh: '配送通知',
    zht: '配送通知',
  },
  'shop-address-deliveryOnlySeoul': {
    ko: '현재 서울 지역에서만 배송 가능합니다.',
    en: 'Delivery is available within Seoul only.',
    zh: '目前仅提供首尔地区配送',
    zht: '目前僅限於首爾地區配送',
  },
  'shop-address-deliveryPlaceHotelPolicies': {
    ko: '호텔 정책에 따라 리셉션 또는 지정된 장소로 배송됩니다.',
    en: "Delivery may be made to the reception or a designated location, as per the hotel's policy.",
    zh: '根据酒店政策，配送可能会送至前台大厅或指定地点',
    zht: '根據酒店政策，配送可能會送至前台或指定地點',
  },
  'shop-address-contactIfAddressIsUnclear': {
    ko: '주소가 불분명할 경우 연락할 수 있습니다.',
    en: 'If the address is unclear, we may contact you for clarification.',
    zh: '若地址信息不明确，我们可能会与您联系以作进一步确认',
    zht: '若地址信息不明確，我們可能會與您聯繫以作進一步確認',
  },
  'shop-address-ordersMayBeCanceledIfDeliveryIsntPossible': {
    ko: '배송이 불가능할 경우 주문이 취소될 수 있습니다.',
    en: 'Orders may be canceled if the delivery cannot be completed.',
    zh: '若配送无法完成，订单将可能被取消',
    zht: '若配送無法完成，訂單將可能被取消',
  },
};
const shopBasket: Expressions = {
  'shop-basket-onlyOneItemAvailable': {
    ko: '단 1개만 남아있습니다',
    en: 'Last one in stock',
    zh: '仅剩1件',
    zht: '僅剩1件',
  },
  'shop-basket-removeItem': {
    ko: '상품 삭제',
    en: 'Remove Item',
    zh: '移除商品',
    zht: '移除商品',
  },
  'shop-basket-modifyOrder': {
    ko: '주문 수정',
    en: 'Modify Order',
    zh: '修改订单',
    zht: '修改訂單',
  },
  'shop-basket-orderNow': {
    ko: '지금 주문하기',
    en: 'Order Now',
    zh: '立即下单',
    zht: '立即下單',
  },
  'shop-basket-optionOutOfStock': {
    ko: '해당 옵션은 품절입니다.',
    en: 'The option is out of stock.',
    zh: '该选项已售罄。',
    zht: '該選項已售罄。',
  },
  'shop-basket-maxOrderLimit': {
    ko: '최대 주문 한도는 {{stockQuantity}}개입니다',
    en: 'The maximum order limit is {{stockQuantity}}',
    zh: '最大订单限制为{{stockQuantity}}',
    zht: '最大訂單限制為{{stockQuantity}}',
  },
  'shop-basket-optionAlreadyAdded': {
    ko: '해당 옵션은 이미 추가되어 선택이 결합됩니다.',
    en: 'The selected option has already been added and will be combined.',
    zh: '该选项已选并将与您的其他选择一同应用。',
    zht: '該選項已選並將與您的其他選擇一同應用。',
  },
  'shop-basket-minOrderQuantity': {
    ko: '최소 주문 수량은 1개입니다',
    en: 'The minimum order quantity is 1',
    zh: '最小订单数量为1',
    zht: '最小訂單數量為1',
  },
  'shop-basket-cancel': {
    ko: '취소',
    en: 'Cancel',
    zh: '取消',
    zht: '取消',
  },
  'shop-basket-optionSelectionChanged': {
    ko: '옵션 선택이 변경되었습니다.',
    en: 'The option selection has been changed.',
    zh: '选项选择已更改。',
    zht: '選項選擇已更改。',
  },
  'shop-basket-removeSelectedItems': {
    ko: '선택한 상품을 삭제하시겠습니까?',
    en: 'Do you want to delete the selected item?',
    zh: '您确定要删除选定的项目吗？',
    zht: '您確定要刪除選定的項目嗎？',
  },
  'shop-basket-remove': {
    ko: '삭제',
    en: 'Remove',
    zh: '移除',
    zht: '移除',
  },
  'shop-basket-buyTotalAmount': {
    ko: '{{totalAvailableAmount}} {{currency}} 구매하기',
    en: 'Buy {{totalAvailableAmount}} {{currency}}',
    zh: '购买{{totalAvailableAmount}} {{currency}}',
    zht: '購買{{totalAvailableAmount}} {{currency}}',
  },
  'shop-basket-reviewAndConsent': {
    ko: '위 주문을 검토하고 동의합니다.',
    en: 'I confirm and accept the above order.',
    zh: '我已审核并同意以上订单',
    zht: '我已審核並同意以上訂單',
  },
  'shop-basket-freeDeliveryAvailable': {
    ko: '무료 배송이 적용되었습니다!',
    en: 'Free Delivery is applied!',
    zh: '免运费已适用',
    zht: '免運費已適用',
  },
  'shop-basket-leftForFreeDelivery': {
    ko: '무료 배송까지 남은 금액',
    en: 'Amount left to get free shipping.',
    zh: '距离免费配送还差金额',
    zht: '距離免運費還差金額',
  },
  'shop-basket-itemCost': {
    ko: '상품 비용',
    en: 'Item Cost',
    zh: '商品费用',
    zht: '商品費用',
  },
  'shop-basket-deliveryFee': {
    ko: '배송비',
    en: 'Delivery Fee',
    zh: '配送费',
    zht: '配送費',
  },
  'shop-basket-total': {
    ko: '총합',
    en: 'TOTAL',
    zh: '计',
    zht: '計',
  },
  'shop-basket-free': {
    ko: '무료',
    en: 'Free',
    zh: '免费',
    zht: '免費',
  },
  'shop-basket-returnToShopping': {
    ko: '쇼핑으로 돌아가기',
    en: 'Return to Shopping',
    zh: '返回购物',
    zht: '返回購物',
  },
  'shop-basket-cartEmpty': {
    ko: '이런! 장바구니가 비어 있습니다!',
    en: 'Oops! Your cart is empty!',
    zh: '您的购物车是空的！',
    zht: '您的購物車是空的！',
  },
  'shop-basket-howAboutThis': {
    ko: '이건 어떠세요?',
    en: 'What about this one?',
    zh: '这个怎么样？',
    zht: '這個怎麼樣？',
  },
  'shop-basket-basket': {
    ko: '장바구니',
    en: 'Cart',
    zh: '购物车',
    zht: '購物車',
  },
  'shop-basket-paymentDetails': {
    ko: '결제 세부 사항',
    en: 'Payment Details',
    zh: '付款详情',
    zht: '付款詳情',
  },
};
const shopComponents: Expressions = {
  'components-selectOptionToAddItem': {
    ko: '다른 상품을 추가하려면 옵션을 선택하세요',
    en: 'Please choose an option to add a new product.',
    zh: '请选择一个选项以添加其他商品。',
    zht: '請選擇一個選項以添加其他商品。',
  },
};
const shopConstants: Expressions = {
  'shop-constants-Pay with Card': {
    ko: '카드로 결제',
    en: 'Pay with Card',
    zh: '使用信用卡支付',
    zht: '使用信用卡支付',
  },
  'shop-constants-Pay with Alipay': {
    ko: '알리페이로 결제',
    en: 'Pay with Alipay',
    zh: '使用支付宝支付',
    zht: '使用支付寶支付',
  },
  'shop-constants-Pay with Alipay, Alipay+': {
    ko: '알리페이로 결제',
    en: 'Pay with Alipay',
    zh: '使用支付宝支付',
    zht: '使用支付寶支付',
  },
  'shop-constants-Waiting for payment!': {
    ko: '결제 대기 중!',
    en: 'Waiting for payment!',
    zh: '等待付款！',
    zht: '等待付款！',
  },
  'shop-constants-Checking your order!': {
    ko: '주문 확인 중!',
    en: 'Checking your order!',
    zh: '正在检查您的订单！',
    zht: '正在檢查您的訂單！',
  },
  'shop-constants-Packing your order!': {
    ko: '주문을 포장하고 있습니다!',
    en: 'Packing your order!',
    zh: '正在打包您的订单！',
    zht: '正在打包您的訂單！',
  },
  'shop-constants-Preparing transit!': {
    ko: '배송 준비 중!',
    en: 'Preparing for shipment!',
    zh: '准备运输！',
    zht: '準備運輸！',
  },
  'shop-constants-Order is on its way!': {
    ko: '주문이 배송 중입니다!',
    en: 'Your order is being shipped!',
    zh: '订单正在配送中！',
    zht: '訂單正在配送中！',
  },
  'shop-constants-Delivery completed!': {
    ko: '배송 완료!',
    en: 'Delivery completed!',
    zh: '配送完成！',
    zht: '配送完成！',
  },
  'shop-constants-Cancellation requested': {
    ko: '취소 요청됨',
    en: 'Cancellation has been requested.',
    zh: '已请求取消',
    zht: '已請求取消',
  },
  'shop-constants-Order canceled': {
    ko: '주문 취소됨',
    en: 'Order canceled',
    zh: '订单已取消',
    zht: '訂單已取消',
  },
  'shop-constants-tomorrow': {
    ko: '내일',
    en: 'tomorrow',
    zh: '明天',
    zht: '明天',
  },
  'shop-constants-today': {
    ko: '오늘',
    en: 'today',
    zh: '今天',
    zht: '今天',
  },
};
const shopContact: Expressions = {
  'shop-contact-confirm': {
    ko: '확인',
    en: 'Confirm',
    zh: '确认',
    zht: '確認',
  },
  'shop-contact-howCanWeReachYou': {
    ko: '어떻게 연락드리면 될까요?',
    en: 'How can we contact you?',
    zh: '请选择您的联系方式。',
    zht: '請選擇您的聯繫方式。',
  },
  'shop-contact-infoForUpdates': {
    ko: '이 정보를 사용하여 주문 및 배송 상태를 업데이트해 드립니다.',
    en: 'We will use this information to update your order and shipping status.',
    zh: '我们将使用此信息更新您的订单和配送状态。',
    zht: '我們將使用此信息更新您的訂單和配送狀態。',
  },
  'shop-contact-autoCancel': {
    ko: '제공된 연락처로 연락이 되지 않을 경우, 주문이 취소될 수 있습니다.',
    en: 'If we cannot reach you at the provided contact, \nwe may need to cancel your order.',
    zh: '如果我们无法通过提供的联系方式联系您，订单可能无法继续处理。',
    zht: '如果我們無法通過提供的聯絡方式聯繫您，訂單可能無法繼續處理。',
  },
  'shop-contact-selectMessenger': {
    ko: '메신저를 선택해 주세요',
    en: 'Please select your contact method.',
    zh: '请选取您的联系方式。',
    zht: '请选取您的联系方式。',
  },
  'shop-contact-enterNumber': {
    ko: '번호를 입력해 주세요',
    en: 'Please enter the number',
    zh: '请输入号码',
    zht: '請輸入號碼',
  },
  'shop-contact-enterEmailAddress': {
    ko: '이메일 주소를 입력해 주세요',
    en: 'Please enter your email address',
    zh: '请输入您的电子邮件地址',
    zht: '請輸入您的電子郵件地址',
  },
  'shop-contact-enterID': {
    ko: 'ID를 입력해 주세요',
    en: 'Please enter the ID',
    zh: '请输入ID',
    zht: '請輸入ID',
  },
  'shop-contact-enterPreferredMethod': {
    ko: '선호하는 연락 방법을 입력해 주세요',
    en: 'Please enter your preferred method',
    zh: '请输入您首选的联系方式',
    zht: '請輸入您首選的聯繫方式',
  },
  'shop-contact-enterCountryCode': {
    ko: '전화번호인 경우 국가 코드를 함께 입력해 주세요.',
    en: 'If entering a phone number, include the country code (+).',
    zh: '如填写电话号码，请包括国家代码（+）。',
    zht: '如填寫電話號碼，請包括國家代碼（+）。',
  },
  'shop-contact-enterNotificationEmail': {
    ko: '알림을 받을 수 있는 이메일 주소를 입력해 주세요.',
    en: 'Please provide an email address to receive notifications.',
    zh: '请提供您的电子邮件地址以便接收通知。',
    zht: '請提供您的電子郵件地址以便接收通知。',
  },
  'shop-contact-enterCorrectID': {
    ko: '올바른 ID를 입력해 주세요.',
    en: 'Please enter the correct ID.',
    zh: '请输入正确的ID。',
    zht: '請輸入正確的ID。',
  },
  'shop-contact-enterPreferredContactMethod': {
    ko: '선호하는 연락 방법을 입력해 주세요.',
    en: 'Please enter the preferred method to contact you.',
    zh: '请输入您的首选联系方式。',
    zht: '請輸入您的首選聯繫方式。',
  },
};
const shopCoupon: Expressions = {
  'shop-coupon-coupon': {
    ko: '쿠폰',
    en: 'Coupon',
    zh: '优惠券',
    zht: '優惠券',
  },
  'shop-coupon-noAvailableCoupons': {
    ko: '사용 가능한 쿠폰이 없습니다',
    en: 'No available coupons',
    zh: '暂无可用优惠券',
    zht: '暫無可用優惠券',
  },
  'shop-coupon-noAvailableCouponsDetailed': {
    ko: '사용 가능한 쿠폰이 없습니다. 보유하신 쿠폰 번호를 입력하시거나 이벤트 페이지를 확인해주세요.',
    en: 'There are no available coupons. Please enter your coupon code or check the event page.',
    zh: '目前没有可用的优惠券。请输入优惠券号码或查看活动页面。',
    zht: '目前沒有可用的優惠券。請輸入優惠券代碼或查看活動頁面。',
  },
  'shop-coupon-thankYouForYourSurvey': {
    ko: '설문조사 참여 감사합니다!',
    en: 'Thank you for your survey!',
    zh: '感谢您帮助我们填写问卷！',
    zht: '感謝您幫助我們填寫問卷！',
  },
  'shop-coupon-issueCouponButtonGuide': {
    ko: '아래 버튼을 클릭해서 쿠폰을 발급받아주세요.',
    en: 'Please click the button below to issue coupon',
    zh: '请点击下面的按钮领取优惠券',
    zht: '請點擊下面的按鈕領取優惠券',
  },
  'shop-coupon-canNotUseBelow': {
    ko: '{{price}} 이하 금액에서는 사용할 수 없습니다.',
    en: '{{price}} Not applicable to amounts below the specified value.',
    zh: '{{price}} 此金额以下无法使用',
    zht: '{{price}} 此金額以下無法使用',
  },
  'shop-coupon-issueCoupons': {
    ko: '쿠폰 발급',
    en: 'Issue Coupons',
    zh: '领取优惠券',
    zht: '領取優惠券',
  },
  'shop-coupon-issued': {
    ko: '발급됨',
    en: 'Issued',
    zh: '已领取',
    zht: '已領取',
  },
  'shop-coupon-couponsAreIssued': {
    ko: '쿠폰이 발급되었습니다.',
    en: 'A coupon has been issued.',
    zh: '优惠券已发放',
    zht: '優惠券已發放',
  },
  'shop-coupon-cannotUnder': {
    ko: '{{threshold}} 미만의 주문금액에 대해서 쿠폰 이용이 불가합니다.',
    en: 'Coupons cannot be used for orders under {{threshold}}.',
    zh: '订单金额低于{{threshold}}时无法使用优惠券。',
    zht: '訂單金額低於{{threshold}}時無法使用優惠券。',
  },
  'shop-coupon-allAvailableCouponsHaveBeenIssued': {
    ko: '모든 쿠폰이 발급되었습니다.',
    en: 'All available coupons have been issued.',
    zh: '所有优惠券已发放完毕。',
    zht: '所有優惠券已發放完畢。',
  },
};
const shopMain: Expressions = {
  'shop-main-orderNowGetItByDeliveryDay': {
    ko: '지금 주문하고 {{deliveryDay}}에 받아보세요',
    en: 'Order Now, Get it by {{deliveryDay}} {{deliveryHour}}',
    zh: '立即下单, 可在{{deliveryDay}}{{deliveryHour}}前收到',
    zht: '立即下單, 可在{{deliveryDay}}{{deliveryHour}}前收到',
  },
  'shop-main-arriveBefore': {
    ko: '{{deliveryHour}}(KST) 이전에 도착',
    en: 'Arrives by {{deliveryHour}}(KST)',
    zh: '{{deliveryHour}}(KST)前送达',
    zht: '{{deliveryHour}}(KST)前送達',
  },
  'shop-main-orderNow': {
    ko: '지금 주문하기!',
    en: 'Order Now!',
    zh: '立即下单！',
    zht: '立即下單！',
  },
  'shop-main-checkHotelAvailability': {
    ko: '호텔 배송 가능 여부 확인',
    en: 'Confirm hotel delivery availability.',
    zh: '请查看您的酒店是否支持配送',
    zht: '請查看您的酒店是否支持配送',
  },
  'shop-main-checkLater': {
    ko: '나중에 확인',
    en: 'Check Later',
    zh: '稍后查看',
    zht: '稍後查看',
  },
  'shop-main-orderNowReceiveItByDeliveryDay': {
    ko: '지금 주문하세요!\n{{deliveryDay}}에 받아보세요',
    en: 'ORDER NOW!\nRECEIVE IT ON {{deliveryDay}}',
    zh: '立即下单！\n在{{deliveryDay}}收货',
    zht: '立即下單！\n在{{deliveryDay}}收貨',
  },
  'shop-main-freeDeliveryOrderOver': {
    ko: '{{cutOffTime}}:00(KST) 전에 100,000₩ 이상 주문하고\n오늘 밤 무료 배송 혜택을 받아보세요!',
    en: 'Place an order over 100,000₩ before {{cutOffTime}}:00(KST)\nand enjoy free delivery to your location by tonight!',
    zh: '在{{cutOffTime}}:00(KST)之前下单满100,000₩\n即可享受当晚免费送货服务！',
    zht: '在{{cutOffTime}}:00(KST)之前下單滿100,000₩\n即可享受當晚免費送貨服務！',
  },
  'shop-main-seoulDeliveryAvailable': {
    ko: '서울 내 배송 가능',
    en: 'Delivery is available for locations within Seoul.',
    zh: '配送服务仅限于首尔地区',
    zht: '配送服務僅限於首爾地區',
  },
  'shop-main-bestPriceOnMustHaves': {
    ko: '필수품 최저가 보장!',
    en: 'Lowest price guarantee on must-have items!',
    zh: '所有必买商品最低价保证！',
    zht: '所有必買商品最低價保證！',
  },
  'shop-main-orderNowGetWithin16Hours': {
    ko: '지금 주문하고 16시간 이내에 받아보세요',
    en: 'Order Now, Get it within 16 hours',
    zh: '现在下单，16小时内送达',
    zht: '現在下單，16小時內送達',
  },
  'shop-main-dontMissThis': {
    ko: '놓치지 마세요!',
    en: "Make sure you don't miss out!",
    zh: '抓住机会，赶快行动！',
    zht: '抓住機會，趕快行動！',
  },
  'shop-main-byTomorrow': {
    ko: '내일까지',
    en: 'by Tomorrow!',
    zh: '明天',
    zht: '明天',
  },
  'shop-main-byToday': {
    ko: '오늘까지',
    en: 'by Today!',
    zh: '今天',
    zht: '今天',
  },
  'shop-main-buyAnother': {
    ko: '{{amount}}개 더 구매',
    en: 'Order {{amount}} More!',
    zh: '再购买{{amount}}件！',
    zht: '再購買{{amount}}件！',
  },
  'shop-main-buyAmount': {
    ko: '{{amount}}개 구매',
    en: 'Order {{amount}} More!',
    zh: '再购买{{amount}}件！',
    zht: '再購買{{amount}}件！',
  },
  'shop-main-addAmountOrMore': {
    ko: '{{amount}} 이상 주문 시',
    en: 'Orders over {{amount}}',
    zh: '订单满{{amount}}以上',
    zht: '訂單滿{{amount}}以上',
  },
  'shop-main-cart': {
    ko: '장바구니',
    en: 'CART',
    zh: '购物车',
    zht: '購物車',
  },
  'shop-main-freeDelivery': {
    ko: '무료 배송',
    en: 'Free Delivery',
    zh: '免费送货',
    zht: '免費送貨',
  },
  'shop-main-free': {
    ko: '무료',
    en: 'Free',
    zh: '免费',
    zht: '免費',
  },
  'shop-main-ifYou': {
    ko: '만약 당신이',
    en: 'If you',
    zh: '如果您',
    zht: '如果您',
  },
  'shop-main-inYourCart': {
    ko: '장바구니에 담긴 상품의 금액은 {{amount}}입니다.',
    en: 'The total amount of items in your cart is {{amount}}.',
    zh: '购物车中商品的金额为{{amount}}。',
    zht: '購物車中商品的金額為{{amount}}。',
  },
  'shop-main-myOrder': {
    ko: '내 주문',
    en: 'MY ORDER',
    zh: '我的订单',
    zht: '我的訂單',
  },
  'shop-main-whereAreYouStaying': {
    ko: '어디에 머무시나요?',
    en: 'Where is your accomodation?',
    zh: '您的住宿在哪里？',
    zht: '您的住宿在哪裡？',
  },
  'shop-main-gotoShop': {
    ko: '샵으로 가기',
    en: 'Go to shop',
    zh: '去商店',
    zht: '去商店',
  },
};
const shopMyOrder: Expressions = {
  'shop-myOrder-cancelOrderPrompt': {
    ko: '주문을 취소하시겠습니까?',
    en: 'Would you like to cancel the order?',
    zh: '是否要取消您的订单？',
    zht: '是否要取消您的訂單？',
  },
  'shop-myOrder-cancelOrder': {
    ko: '주문 취소',
    en: 'Cancel Order',
    zh: '取消订单',
    zht: '取消訂單',
  },
  'shop-myOrder-return': {
    ko: '돌아가기',
    en: 'Return',
    zh: '返回',
    zht: '返回',
  },
  'shop-myOrder-outOfStockCancelPrompt': {
    ko: '상품이 품절된 경우 재주문이 어려울 수 있습니다.\n주문을 취소하시겠습니까?',
    en: 'If the item is no longer in stock, reordering could be difficult. Would you like to cancel your order?',
    zh: '商品若已售罄，可能无法再订购。您想要取消订单吗？',
    zht: '商品若已售罄，可能無法再訂購。您想要取消訂單嗎？',
  },
  'shop-myOrder-stayUpdatedAlerts': {
    ko: '중요 알림을 받아보세요!',
    en: 'Stay Updated on Important Information!',
    zh: '随时掌握重要信息。',
    zht: '隨時掌握重要資訊。',
  },
  'shop-myOrder-turnOn': {
    ko: '켜기',
    en: 'Turn On',
    zh: '打开',
    zht: '打開',
  },
  'shop-myOrder-skip': {
    ko: '건너뛰기',
    en: 'Skip',
    zh: '跳过',
    zht: '跳過',
  },
  'shop-myOrder-realTimeUpdatesPrompt': {
    ko: '중요 정보를 실시간으로 업데이트 받으세요. 언제든지 알림 설정을 조정할 수 있습니다.\n건너뛰기를 선택하면 알림 요청이 6시간 동안 숨겨집니다.',
    en: "Stay updated with real-time information. You can adjust your notification preferences at any time.\nChoosing 'Skip' will hide the notification request for 6 hours.",
    zh: '随时接收实时信息更新，您可以根据需要调整通知设置。\n点击‘跳过’将隐藏通知 6 小时。',
    zht: '隨時接收即時資訊更新，您可以根據需要調整通知設定。\n點擊‘跳過’將隱藏通知 6 小時。',
  },
  'shop-myOrder-previouslyBlockedNotifications': {
    ko: '이 기기에서 이전에 알림을 차단하셨나요?',
    en: 'Have you turned off notifications on this device before?',
    zh: '请问您之前是否有关闭过此设备的通知？',
    zht: '請問您之前是否有關閉過此設備的通知？',
  },
  'shop-myOrder-changeSettingsPrompt': {
    ko: '이전에 알림을 차단한 경우 브라우저나 앱 설정을 변경해야 합니다.',
    en: 'If you have blocked notifications previously, you will need to change the settings in your browser or app.',
    zh: '如果您之前已关闭通知，请在浏览器或应用程序设置中进行调整。',
    zht: '如果您之前已关闭通知，請在瀏覽器或應用程式設定中進行調整。',
  },
  'shop-myOrder-iosNotificationSettings': {
    ko: 'iOS의 경우 설정 > 알림 > Kup > 알림을 활성화하기.',
    en: 'For iOS users, go to Settings > Notifications > Kup > Enable notifications.',
    zh: 'iOS用户, 前往设置 > 通知 > Kup > 启用通知。',
    zht: 'iOS用戶, 前往設置 > 通知 > Kup > 啟用通知。',
  },
  'shop-myOrder-androidNotificationSettings': {
    ko: 'Android의 경우 설정 > 앱 및 알림 > Kup > 알림에서 활성화하세요.',
    en: 'For Android users, go to Settings > Apps & notifications > Kup > Enable Notifications.',
    zh: 'Android用户 ,前往设置 > 应用和通知 > Kup > 启用通知。',
    zht: 'Android用戶, 前往設置 > 應用和通知 > Kup > 啟用通知。',
  },
  'shop-myOrder-cancellation': {
    ko: '취소',
    en: 'Cancellation',
    zh: '取消',
    zht: '取消',
  },
  'shop-myOrder-goBackToOrders': {
    ko: '내 주문으로 돌아가기',
    en: 'Go Back to My Orders',
    zh: '返回我的订单',
    zht: '返回我的訂單',
  },
  'shop-myOrder-orderCanceled': {
    ko: '주문이 취소되었습니다',
    en: 'Your order has been canceled',
    zh: '您的订单已取消',
    zht: '您的訂單已取消',
  },
  'shop-myOrder-cancellationTimeframe': {
    ko: '결제 수단에 따라 취소 금액은 3~7일 내에 확인할 수 있습니다.',
    en: 'The cancellation amount can be confirmed in 3 to 7 days, based on the payment method used.',
    zh: '根据付款方式, 取消金额可在3到7天内查阅。',
    zht: '根據付款方式, 取消金額可在3到7天內查閱。',
  },
  'shop-myOrder-amountOfGoods': {
    ko: '상품 금액',
    en: 'Product price',
    zh: '商品金额',
    zht: '商品金額',
  },
  'shop-myOrder-deliveryCharge': {
    ko: '배송비',
    en: 'Delivery fee',
    zh: '配送费',
    zht: '配送費',
  },
  'shop-myOrder-appliedCoupon': {
    ko: '적용된 쿠폰',
    en: 'Applied coupon',
    zh: '已使用的优惠券',
    zht: '已使用的優惠券',
  },
  'shop-myOrder-appliedSpecificCoupon': {
    ko: '{{appliedCouponName}} 적용',
    en: 'Applied {{appliedCouponName}}',
    zh: '已使用{{appliedCouponName}}',
    zht: '已使用{{appliedCouponName}}',
  },
  'shop-myOrder-deliveryFinishedBy10pm': {
    ko: '{{deliveryDay}} {{deliveryHour}}:00(KST) 이전에 배송이 완료됩니다.',
    en: 'Delivery would be completed before {{deliveryHour}}:00(KST) {{deliveryDay}}.',
    zh: '{{deliveryDay}}{{deliveryHour}}:00(KST)前完成配送。',
    zht: '{{deliveryDay}}{{deliveryHour}}:00(KST)前完成配送。',
  },
  'shop-myOrder-deliveryInfo': {
    ko: '배송 정보',
    en: 'Delivery Info',
    zh: '配送信息',
    zht: '配送信息',
  },
  'shop-myOrder-address': {
    ko: '주소',
    en: 'Address',
    zh: '地址',
    zht: '地址',
  },
  'shop-myOrder-addressDetail': {
    ko: '상세 주소',
    en: 'Detailed Address',
    zh: '详细地址',
    zht: '詳細地址',
  },
  'shop-myOrder-request': {
    ko: '요청사항',
    en: 'Request',
    zh: '请求事项',
    zht: '請求事項',
  },
  'shop-myOrder-contact': {
    ko: '연락처',
    en: 'Contact',
    zh: '联系方式',
    zht: '聯繫方式',
  },
  'shop-myOrder-email': {
    ko: '이메일',
    en: 'Email',
    zh: '电子邮件',
    zht: '電子郵件',
  },
  'shop-myOrder-status': {
    ko: '상태',
    en: 'Status',
    zh: '状态',
    zht: '狀態',
  },
  'shop-myOrder-changeDestinationPrompt': {
    ko: '목적지를 변경하려면 취소 후 재주문해 주세요.',
    en: "If you'd like to change the destination, please cancel the current order and place a new one.",
    zh: '如果您想更改目的地，请取消当前订单并重新下单。',
    zht: '如果您想更改目的地，请取消当前订单并重新下单。',
  },
  'shop-myOrder-customer': {
    ko: '고객',
    en: 'Customer',
    zh: '客户',
    zht: '客戶',
  },
  'shop-myOrder-orderNo': {
    ko: '주문 번호',
    en: 'Order No.',
    zh: '订单号',
    zht: '訂單號',
  },
  'shop-myOrder-orderDate': {
    ko: '주문 날짜',
    en: 'Order Date',
    zh: '订单日期',
    zht: '訂單日期',
  },
  'shop-myOrder-orderAmount': {
    ko: '주문 금액',
    en: 'Order Amount',
    zh: '订单金额',
    zht: '訂單金額',
  },
  'shop-myOrder-total': {
    ko: '총합',
    en: 'TOTAL',
    zh: '计',
    zht: '計',
  },
  'shop-myOrder-paymentAmount': {
    ko: '결제 금액',
    en: 'Payment Amount',
    zh: '支付金额',
    zht: '支付金額',
  },
  'shop-myOrder-paymentMethod': {
    ko: '결제 수단',
    en: 'Payment Method',
    zh: '支付方式',
    zht: '支付方式',
  },
  'shop-myOrder-confirmed': {
    ko: '확인됨',
    en: 'Confirmed',
    zh: '已确认',
    zht: '已確認',
  },
  'shop-myOrder-cannotCancelInTransit': {
    ko: '주문이 배송 중인 경우 취소할 수 없습니다.',
    en: 'Orders in transit cannot be canceled.',
    zh: '订单已发货后，无法进行取消',
    zht: '訂單已發貨後，無法進行取消',
  },
  'shop-myOrder-orderInquiryOrRefund': {
    ko: '주문 문의 또는 환불 요청',
    en: 'Order inquiry or Refund request',
    zh: '订单查询或退款请求',
    zht: '訂單查詢或退款請求',
  },
  'shop-myOrder-agreements': {
    ko: '약관',
    en: 'Terms and Conditions',
    zh: '协议',
    zht: '協議',
  },
  'shop-myOrder-minorAgreementCancel': {
    ko: '미성년자가 체결한 계약은 미성년자 본인이나 법정대리인이 취소할 수 있으며, 법정대리인이 동의하지 않을 경우 취소 가능합니다.',
    en: 'A contract concluded by a minor can be canceled by the minor or their legal representative, and if the legal representative does not consent, the contract can be canceled.',
    zh: '未成年人签订的合同可以由未成年人本人或其法定代理人取消,如果法定代理人不同意,合同可以被取消。',
    zht: '未成年人簽訂的合同可以由未成年人本人或其法定代理人取消,如果法定代理人不同意,合同可以被取消。',
  },
  'shop-myOrder-itemCount': {
    ko: '{{itemCount}}개 상품',
    en: '{{itemCount}} items',
    zh: '{{itemCount}}件商品',
    zht: '{{itemCount}}件商品',
  },
  'shop-myOrder-myOrders': {
    ko: '내 주문',
    en: 'My Orders',
    zh: '我的订单',
    zht: '我的訂單',
  },
  'shop-myOrder-orderHistory': {
    ko: '주문 내역',
    en: 'Order History',
    zh: '订单历史',
    zht: '訂單歷史',
  },
  'shop-myOrder-moreProducts': {
    ko: '더 많은 상품',
    en: 'More Products',
    zh: '更多商品',
    zht: '更多商品',
  },

  'shop-myOrder-orderInfo': {
    ko: '주문 정보',
    en: 'Order Info',
    zh: '订单信息',
    zht: '訂單信息',
  },
  'shop-myOrder-totalAmount': {
    ko: '총 금액',
    en: 'Total Amount',
    zh: '总金额',
    zht: '總金額',
  },

  'shop-myOrder-Waiting for payment!': {
    ko: '결제 대기 중!',
    en: 'Waiting for payment!',
    zh: '等待付款！',
    zht: '等待付款！',
  },
  'shop-myOrder-Checking your order!': {
    ko: '주문 확인 중!',
    en: 'Checking your order!',
    zh: '正在检查您的订单！',
    zht: '正在檢查您的訂單！',
  },
  'shop-myOrder-Packing your order!': {
    ko: '주문을 포장하고 있습니다!',
    en: 'Packing your order!',
    zh: '正在打包您的订单！',
    zht: '正在打包您的訂單！',
  },
  'shop-myOrder-Preparing transit!': {
    ko: '배송 준비 중!',
    en: 'Preparing for shipment!',
    zh: '准备运输！',
    zht: '準備運輸！',
  },
  'shop-myOrder-Order is on its way!': {
    ko: '주문이 배송 중입니다!',
    en: 'Your order is being shipped!',
    zh: '订单正在配送中！',
    zht: '訂單正在配送中！',
  },
  'shop-myOrder-Delivery completed!': {
    ko: '배송 완료!',
    en: 'Delivery completed!',
    zh: '配送完成！',
    zht: '配送完成！',
  },
  'shop-myOrder-Cancellation requested': {
    ko: '취소 요청됨',
    en: 'Cancellation requested',
    zh: '已请求取消',
    zht: '已請求取消',
  },
  'shop-myOrder-Order canceled': {
    ko: '주문 취소됨',
    en: 'Order canceled',
    zh: '订单已取消',
    zht: '訂單已取消',
  },
};
const shopOrder: Expressions = {
  'shop-order-productsOutOfStock': {
    ko: '일부 제품이 품절되었습니다 :(',
    en: 'Some of the products are out of stock :(',
    zh: '部分商品目前缺货 :(',
    zht: '部分商品暫時沒有庫存 :(',
  },
  'shop-order-reorderCheckInventory': {
    ko: '재고를 확인한 후 다시 주문해 주세요',
    en: 'Kindly reorder after confirming the inventory.',
    zh: '请在检查库存后重新下单',
    zht: '請在檢查庫存後重新下單',
  },
  'shop-order-backToCart': {
    ko: '장바구니로 돌아가기',
    en: 'Back to my cart',
    zh: '返回购物车',
    zht: '返回購物車',
  },
  'shop-order-payOnDelivery': {
    ko: '{time} 사이에 결제',
    en: 'Complete the payment between {time}',
    zh: '在{time}之间付款',
    zht: '在{time}之間付款',
  },
  'shop-order-deliveryTime': {
    ko: '배송 시간은 {time} 사이입니다',
    en: 'The delivery time is between {time}',
    zh: '配送时间为{time}之间',
    zht: '配送時間為{time}之間',
  },
  'shop-order-paymentUponDelivery': {
    ko: '배송 시 직접 결제가 이루어집니다.',
    en: 'Payment will be made directly upon delivery.',
    zh: '货到付款将在配送时进行',
    zht: '貨到付款將在配送時進行',
  },
  'shop-order-autoRefundPolicy': {
    ko: '연락이 닿지 않거나 도착 후 15분 내에 배송이 불가능할 경우 주문이 자동으로 환불될 수 있습니다.',
    en: 'If we are unable to reach you or if delivery cannot be completed within 15 minutes of arrival, the order may be automatically refunded.',
    zh: '如果我们无法联系到您，或者配送在到达后15分钟内无法完成，订单可能会自动退款。',
    zht: '如果我們無法聯繫到您，或者配送在到達後15分鐘內無法完成，訂單可能會自動退款。',
  },
  'shop-order-contactBeforeDelivery': {
    ko: '배송 전에 연락을 드리겠습니다.',
    en: 'We will contact you before delivery.',
    zh: '配送前我们会联系您',
    zht: '配送前我們會聯繫您',
  },
  'shop-order-checkContactInfo': {
    ko: '연락처 정보와 알림 설정을 확인해 주세요.',
    en: 'Please check your contact information and notification settings.',
    zh: '请检查您的联系方式和通知设置。',
    zht: '請檢查您的聯繫方式和通知設置。',
  },
  'shop-order-errorPostOrder': {
    ko: '주문 처리 오류',
    en: 'Order processing error',
    zh: '订单处理错误',
    zht: '訂單處理錯誤',
  },
  'shop-order-orderSummary': {
    ko: '{{orderProductName}} 외 {{remainingItems}}개',
    en: '{{orderProductName}} and {{remainingItems}} more',
    zh: '{{orderProductName}}及其他{{remainingItems}}件',
    zht: '{{orderProductName}}及其他{{remainingItems}}件',
  },
  'shop-order-paymentCanceled': {
    ko: '결제가 취소되었습니다.',
    en: 'Your payment has been canceled.',
    zh: '您的付款已取消。',
    zht: '您的付款已取消。',
  },
  'shop-order-enterAddress': {
    ko: '주소를 입력해 주세요.',
    en: 'Please enter your address.',
    zh: '请输入您的地址。',
    zht: '請輸入您的地址。',
  },
  'shop-order-enterContact': {
    ko: '연락처를 입력해 주세요.',
    en: 'Please enter your contact.',
    zh: '请输入您的联系方式。',
    zht: '請輸入您的聯繫方式。',
  },
  'shop-order-enterNameEmail': {
    ko: '이름과 이메일을 입력해 주세요.',
    en: 'Please enter your name and email address.',
    zh: '请输入您的姓名和电子邮箱。',
    zht: '請輸入您的姓名和電子郵箱。',
  },
  'shop-order-confirmCondition': {
    ko: '조건을 확인해 주세요.',
    en: 'Please check the conditions.',
    zh: '请确认条件。',
    zht: '請確認條件。',
  },
  'shop-order-agreeToTerms': {
    ko: '동의합니다 ',
    en: 'I have reviewed the terms and order details and agree to provide information.',
    zh: '我已确认条款和订单内容，并同意提供信息。',
    zht: '我已確認條款和訂單內容，並同意提供資訊。',
  },
  'shop-order-checkpopup': {
    ko: '팝업 상태와 네트워크를 확인해주세요.',
    en: 'Please check the popup and network status.',
    zh: '请检查弹窗和网络设置。',
    zht: '請檢查彈窗和網路設定。',
  },
  'shop-order-agreements': {
    ko: '약관',
    en: 'Terms and Conditions',
    zh: '协议',
    zht: '協議',
  },
  'shop-order-orderTerms': {
    ko: '주문약관',
    en: 'Order Terms and Conditions',
    zh: '订单条款',
    zht: '訂單條款',
  },
  'shop-order-viewDetails': {
    ko: '상세보기',
    en: 'View Details',
    zh: '查看详情',
    zht: '查看詳情',
  },
  'shop-order-placeOrder': {
    ko: '주문하기',
    en: 'Place Order',
    zh: '下单',
    zht: '下單',
  },
  'shop-order-deliveryInfo': {
    ko: '배송 정보',
    en: 'Delivery Info',
    zh: '配送信息',
    zht: '配送信息',
  },
  'shop-order-modify': {
    ko: '수정',
    en: 'Modify',
    zh: '修改',
    zht: '修改',
  },
  'shop-order-detailAddress': {
    ko: '상세 주소 정보',
    en: 'Detailed address information',
    zh: '详细地址',
    zht: '詳細地址',
  },
  'shop-order-instructionRequest': {
    ko: '배송 시 요청 사항',
    en: 'Instructions or requests for delivery',
    zh: '配送备注',
    zht: '配送備註',
  },
  'shop-order-hotelStay': {
    ko: '머무는 호텔은 어디인가요?',
    en: 'Where are you staying?',
    zh: '你入住的是哪家酒店？',
    zht: '你入住的是哪家酒店？',
  },
  'shop-order-letMeKnow': {
    ko: '배송 관련 요청을 알려주세요',
    en: 'Please provide your delivery instructions.',
    zh: '请告知我们您的配送相关要求',
    zht: '請告知我们您的配送相關要求',
  },
  'shop-order-search': {
    ko: '검색',
    en: 'Search',
    zh: '搜索',
    zht: '搜索',
  },
  'shop-order-setAddress': {
    ko: '주소입력',
    en: 'Enter Delivery Address',
    zh: '输入地址',
    zht: '輸入地址',
  },
  'shop-order-setContact': {
    ko: '연락처입력',
    en: 'Enter Contact Information',
    zh: '输入联系方式',
    zht: '輸入聯絡方式',
  },
  'shop-order-checkAddress': {
    ko: '주소가 정확한지 확인해 주세요.',
    en: 'Kindly confirm the address.',
    zh: '请确认地址是否正确。',
    zht: '請確認地址是否正確。',
  },
  'shop-order-contactInfo': {
    ko: '연락처 정보',
    en: 'Contact Info',
    zh: '联系方式',
    zht: '聯繫方式',
  },
  'shop-order-reachYou': {
    ko: '어떻게 연락드리면 될까요?',
    en: 'How can we contact you?',
    zh: '请选择您的联系方式。',
    zht: '請選擇您的聯繫方式。',
  },
  'shop-order-willContactYou': {
    ko: '필요한 경우 이 연락처로 배송을 위한 연락을 드릴 예정입니다.',
    en: 'If needed, we will contact you for delivery.',
    zh: '如有需要，我们会联系您安排配送。',
    zht: '如有需要，我們會聯繫您安排配送。',
  },
  'shop-order-setting': {
    ko: '설정',
    en: 'Setting',
    zh: '设置',
    zht: '設置',
  },
  'shop-order-orderInfo': {
    ko: '주문 정보',
    en: 'Order Info',
    zh: '订单信息',
    zht: '訂單信息',
  },
  'shop-order-receiverName': {
    ko: '주문 정보 수신자 이름',
    en: 'Name to receive order info',
    zh: '接收订单信息的姓名',
    zht: '接收訂單信息的姓名',
  },
  'shop-order-receiverEmail': {
    ko: '주문 정보 수신 이메일',
    en: 'Email to receive order info',
    zh: '接收订单信息的电子邮件',
    zht: '接收訂單信息的電子郵件',
  },
  'shop-order-invoiceEmail': {
    ko: '확인 후 송장이 이메일로 발송됩니다',
    en: 'After confirmation, the invoice will be emailed to you',
    zh: '确认后,发票将通过电子邮件发送给您',
    zht: '確認後,發票將通過電子郵件發送給您',
  },
  'shop-order-payment': {
    ko: '결제',
    en: 'Payment',
    zh: '支付',
    zht: '支付',
  },
  'shop-order-coupon': {
    ko: '쿠폰',
    en: 'Coupon',
    zh: '优惠券',
    zht: '優惠券',
  },
  'shop-order-appliedCoupon': {
    ko: '{{appliedCouponName}}이(가) 적용되었습니다',
    en: '{{appliedCouponName}} is applied',
    zh: '{{appliedCouponName}}已使用',
    zht: '{{appliedCouponName}}已使用',
  },
  'shop-order-availableCoupons': {
    ko: '사용 가능한 쿠폰 {{availableCouponCount}}개',
    en: '{{availableCouponCount}} coupons available',
    zh: '可使用的优惠券数量为{{availableCouponCount}}张',
    zht: '可使用的優惠券數量為{{availableCouponCount}}張',
  },
  'shop-order-totalItems': {
    ko: '{{totalItems}}개 상품',
    en: '{{totalItems}} items',
    zh: '{{totalItems}}件商品',
    zht: '{{totalItems}}件商品',
  },
  'shop-order-toBePaid': {
    ko: '결제 예정 금액',
    en: 'Amount to be paid',
    zh: '待支付金额',
    zht: '待支付金額',
  },
  'shop-order-itemCost': {
    ko: '상품 금액',
    en: 'Product price',
    zh: '商品金额',
    zht: '商品金額',
  },
  'shop-order-deliveryFee': {
    ko: '배송비',
    en: 'Delivery Fee',
    zh: '配送费',
    zht: '配送費',
  },
  'shop-order-couponLabel': {
    ko: '쿠폰',
    en: 'Coupon',
    zh: '优惠券',
    zht: '優惠券',
  },
  'shop-order-appliedCouponMessage': {
    ko: '{{appliedCouponName}} 쿠폰이 적용되었습니다',
    en: '{{appliedCouponName}} coupon is applied',
    zh: '已使用{{appliedCouponName}}优惠券',
    zht: '已使用{{appliedCouponName}}優惠券',
  },
  'shop-order-total': {
    ko: '총합',
    en: 'TOTAL',
    zh: '计',
    zht: '計',
  },
  'shop-order-cardPaymentPage': {
    ko: '카드 결제 페이지가 표시됩니다',
    en: 'The card payment page will be displayed.',
    zh: '将显示信用卡支付页面',
    zht: '將顯示信用卡支付頁面',
  },
  'shop-order-order': {
    ko: '주문',
    en: 'Order',
    zh: '下单',
    zht: '下單',
  },

  'shop-order-free': {
    ko: '무료',
    en: 'Free',
    zh: '免费',
    zht: '免費',
  },

  'shop-order-goToOrderDetail': {
    ko: '주문 상세로 이동 ({remainTime}초)',
    en: 'Go to Order Detail ({remainTime}s)',
    zh: '前往订单详情 ({remainTime}秒)',
    zht: '前往訂單詳情 ({remainTime}秒)',
  },
  'shop-order-orderProcessed': {
    ko: '주문이 처리되었습니다',
    en: 'Your order has been processed',
    zh: '您的订单已处理',
    zht: '您的訂單已處理',
  },
  'shop-order-failed': {
    ko: '주문에 실패했습니다',
    en: 'Order failed',
    zh: '订单失败',
    zht: '訂單失敗',
  },
  'shop-order-failDescription': {
    ko: '죄송합니다. 주문 처리 중 문제가 발생했습니다. 다시 시도해 주세요.',
    en: 'Sorry, there was an issue processing your order. Please try again.',
    zh: '抱歉,处理您的订单时出现问题。请再试一次。',
    zht: '抱歉,處理您的訂單時出現問題。請再試一次。',
  },
  'shop-order-backToBasket': {
    ko: '장바구니로 돌아가기',
    en: 'Back to Basket',
    zh: '返回购物篮',
    zht: '返回購物籃',
  },
};
const shopProduct: Expressions = {
  'shop-product-availableDuringTour': {
    ko: '투어 날짜에만 이용 가능합니다!',
    en: 'This deal is available for the tour date only!',
    zh: '仅限于旅游日期使用！',
    zht: '僅限於旅遊日期使用！',
  },
  'shop-product-oneItemPerCustomer': {
    ko: '고객당 한 개의 상품만 구매 가능합니다',
    en: 'Each customer can purchase only one item',
    zh: '每位顾客仅限购买一件商品',
    zht: '每位顧客僅限購買一件商品',
  },
  'shop-product-maxOrderLimit': {
    ko: '최대 주문 한도는 {{properQuantity}}개입니다',
    en: 'The maximum order limit is {{properQuantity}}',
    zh: '最大订购数量为{{properQuantity}}件',
    zht: '最大訂購數量為{{properQuantity}}件',
  },
  'shop-product-onlyOneItemAvailable': {
    ko: '단 1개만 남아있습니다',
    en: 'Only 1 item available',
    zh: '仅剩1件',
    zht: '僅剩1件',
  },
  'shop-product-itemsLeft': {
    ko: '{{stockQuantity}}개 남음',
    en: '{{stockQuantity}} left',
    zh: '剩余{{stockQuantity}}件',
    zht: '剩餘{{stockQuantity}}件',
  },
  'shop-product-total': {
    ko: '총합',
    en: 'TOTAL',
    zh: '计',
    zht: '計',
  },
  'shop-product-orderWithinToReceive': {
    ko: '{{deliveryDay}} {{departureHour}} - {{deliveryHour}}(KST)에 받으세요',
    en: 'Receive it on {{deliveryDay}} between {{departureHour}} - {{deliveryHour}} (KST)',
    zh: '{{deliveryDay}} {{departureHour}} - {{deliveryHour}}(KST)送达',
    zht: '{{deliveryDay}} {{departureHour}} - {{deliveryHour}}(KST)送達',
  },
  'shop-product-addToBasket': {
    ko: '장바구니에 추가',
    en: 'Add to cart',
    zh: '加入购物车',
    zht: '加入購物車',
  },
  'shop-product-optionOutOfStock': {
    ko: '해당 옵션은 품절입니다.',
    en: 'This option is out of stock.',
    zh: '该选项已售罄。',
    zht: '該選項已售罄。',
  },
  'shop-product-optionAlreadySelected': {
    ko: '해당 옵션이 이미 선택되었습니다.',
    en: 'The option is already selected.',
    zh: '该选项已被选择。',
    zht: '該選項已被選擇。',
  },
  'shop-product-soldOut': {
    ko: '품절',
    en: 'Sold Out',
    zh: '售罄',
    zht: '售罄',
  },
  'shop-product-orderLimitReached': {
    ko: '주문 한도에 도달했습니다',
    en: 'Order Limit Reached',
    zh: '已达订单限制',
    zht: '已達訂單限制',
  },
  'shop-product-buy': {
    ko: '구매',
    en: 'Buy',
    zh: '购买',
    zht: '購買',
  },
  'shop-product-best': {
    ko: 'BEST',
    en: 'BEST',
    zh: 'BEST',
    zht: 'BEST',
  },
  'shop-product-hotDeal': {
    ko: '🔥 HOT DEAL',
    en: '🔥 HOT DEAL',
    zh: '🔥 热门优惠',
    zht: '🔥 熱門優惠',
  },
  'shop-product-dontMissThis': {
    ko: '이 상품을 놓치지 마세요!',
    en: "Don't miss out on this product!",
    zh: '不要错过这个商品！',
    zht: '不要錯過這個商品！',
  },
  'shop-product-aiTranslationDisclaimer': {
    ko: '명확성을 위해 AI로 번역된 이미지입니다.\nAI는 오류를 범할 수 있습니다. 중요한 정보는 확인해 주세요.',
    en: 'Images translated by AI for clarity.\nAI can make mistakes. Please verify important information.',
    zh: '图像由AI翻译以提高清晰度。\nAI可能会出错。请核实重要信息。',
    zht: '圖像由AI翻譯以提高清晰度。\nAI可能會出錯。請核實重要信息。',
  },
  'shop-product-hideDetails': {
    ko: '세부 정보 숨기기',
    en: 'Hide Details',
    zh: '隐藏详情',
    zht: '隱藏詳情',
  },
  'shop-product-viewMoreDetails': {
    ko: '더 많은 세부 정보 보기',
    en: 'View More Details',
    zh: '查看更多详情',
    zht: '查看更多詳情',
  },
  'shop-product-orderWithinReceiveToday': {
    ko: '{{timeUntilCutOff}} 내에 주문하여\n{{deliveryDay}} {{deliveryHour}}:00(KST) 전에 받으세요',
    en: 'Place order within {{timeUntilCutOff}} to receive\ndelivery before {{deliveryHour}}:00(KST) {{deliveryDay}}',
    zh: '在{{timeUntilCutOff}}内下单,\n{{deliveryDay}}{{deliveryHour}}:00(KST)前送达',
    zht: '在{{timeUntilCutOff}}內下單,\n{{deliveryDay}}{{deliveryHour}}:00(KST)前送達',
  },
  'shop-product-oneItemPerCustomerDeal': {
    ko: '이 딜은 고객당 한 개의 상품만 구매 가능합니다 🔥',
    en: 'This deal is limited to one item per customer. 🔥',
    zh: '本优惠每位顾客仅限购买一件🔥',
    zht: '本優惠每位顧客僅限購買一件🔥',
  },
  'shop-product-Translated (AI)': {
    ko: '번역본 (AI)',
    en: 'Translated (AI)',
    zh: '翻译（AI）',
    zht: '翻譯（AI）',
  },
  'shop-product-Original (Korean)': {
    ko: '원본 (한국어)',
    en: 'Original (Korean)',
    zh: '原文（韩文）',
    zht: '原文（韓文）',
  },
  'shop-product-productDetail': {
    ko: '상품 상세',
    en: 'Product Detail',
    zh: '商品详情',
    zht: '商品詳情',
  },
  'shop-product-orderBy-recommendation': {
    ko: '추천순',
    en: 'Recommended',
    zh: '推荐排序',
    zht: '推薦排序',
  },
  'shop-product-orderBy-price-low-to-high': {
    ko: '가격 낮은 순',
    en: 'Price: Low to High',
    zh: '价格从低到高',
    zht: '價格從低到高',
  },
  'shop-product-orderBy-price-high-to-low': {
    ko: '가격 높은 순',
    en: 'Price: High to Low',
    zh: '价格从高到低',
    zht: '價格從高到低',
  },
  'shop-product-orderBy-sales': {
    ko: '판매순',
    en: 'Best Selling',
    zh: '热销',
    zht: '熱銷',
  },
};

export default {
  ...common,
  ...component,
  ...homeMain,

  ...discoveryContent,
  ...discoveryMain,
  ...discoveryPlace,

  ...messageChat,
  ...messageMain,

  ...reservationMain,
  ...reservationConfirm,
  ...reservationName,

  ...shopAddress,
  ...shopBasket,
  ...shopComponents,
  ...shopConstants,
  ...shopContact,
  ...shopCoupon,
  ...shopMain,
  ...shopMyOrder,
  ...shopOrder,
  ...shopProduct,
} as Expressions;
