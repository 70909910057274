import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type StateSetter<S> = (prevState: S) => S;
type SetStateAction<S> = S | StateSetter<S>;
type KeepStateSetter = (key: string, actionValue: SetStateAction<unknown>) => void;
type KeepStateGetter = (key: string, ifNot?: unknown) => unknown;

type PathState = {
  [key: string]: unknown;
};

type GlobalState = {
  [uri: string]: PathState;
};

type KeepState = [KeepStateGetter, KeepStateSetter, GlobalState];

export const KeepStateContext = createContext<KeepState | null>(null);

type KeepStateProviderProps = { children: ReactNode };

export function KeepStateProvider(props: KeepStateProviderProps) {
  const { children } = props;
  const [globalState, setGlobalState] = useState<GlobalState>({});

  const setter: KeepStateSetter = useCallback(
    (key, actionValue) => {
      const location = window.location;
      const locationUri = `${location.pathname}${location.hash}${location.search}`;
      setGlobalState((prevState) => ({
        ...prevState,
        [locationUri]: {
          ...(prevState[locationUri] ?? {}),
          [key]: isStateSetter(actionValue)
            ? actionValue(prevState[locationUri]?.[key] as string)
            : actionValue,
        },
      }));
    },
    [setGlobalState]
  );

  const getter: KeepStateGetter = (key, ifNot) => {
    const location = window.location;
    const locationUri = `${location.pathname}${location.hash}${location.search}`;
    if (locationUri in globalState && key in globalState[locationUri])
      return globalState[locationUri][key];
    return ifNot;
  };

  return (
    <KeepStateContext.Provider value={[getter, setter, globalState]}>
      {children}
    </KeepStateContext.Provider>
  );
}

export function useKeepStateContext() {
  return useContext(KeepStateContext);
}

function isStateSetter<S>(action: SetStateAction<S>): action is StateSetter<S> {
  return typeof action === 'function';
}
