import { useState, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useKupStore from '~kup/store';
import { OrderItemState } from '~/pages/shop/types';
import useSyncBasket from '~/pages/shop/hooks/useSyncBasket.ts';
import Page from '~/components/Page';
import Container from '~/components/Container.tsx';
import Button from '~/components/Button.tsx';
import Checkbox, { BareCheckboxProps } from '~/components/Checkbox';
import Stack from '~/components/Stack.tsx';
import Typography from '~/components/Typography.tsx';
import useDelivery from '~/pages/shop/hooks/useDelivery.ts';
import IconButton from '~/components/IconButton.tsx';
import Divider from '~/components/Divider';
import ProductList from '~/components/ProductList.tsx';
import useProducts from '~/hooks/useProducts.ts';
import BasketItem from '~/pages/shop/basket/components/BasketItem.tsx';
import Area from '~/components/Area.tsx';
// import Progress from '~/components/Progress.tsx';

import { BasketProductItem } from '~kup/models/Basket.ts';
import ModifyItemPopover from '~/pages/shop/basket/components/ModifyItemPopover.tsx';
import RemoveItemsPopover from '~/pages/shop/basket/components/RemoveItemsPopover.tsx';
import Spotlight from '~/components/Spotlight.tsx';
import { useTranslation } from 'react-i18next';

type ItemGroups = {
  productId: string;
  productName: string;
  items: BasketProductItem[];
};

export default function ShopBasketPage() {
  useSyncBasket();
  const { setAppliedCoupon } = useKupStore((state) => ({
    setAppliedCoupon: state.setAppliedCoupon,
  }));
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { currency, basket, basketChecks, setBasketCheck, setBasketChecks } = useKupStore();
  const isAllChecked =
    Object.values(basketChecks).length > 0 &&
    Object.values(basketChecks).reduce((a, b) => a && b, true);

  const checkedItems = basket.items.filter((item) => basketChecks[item.key]);
  const checkedAmount = checkedItems.map((i) => i.amount).reduce((a, b) => a + b, 0);
  const checkedAvailableAmount = checkedItems
    .map((i) => i.availableAmount)
    .reduce((a, b) => a + b, 0);

  const delivery = useDelivery(checkedAvailableAmount);
  const deliveryFee = checkedAvailableAmount > 0 ? delivery.fee : 0;
  const totalOrderAvailableAmount = checkedAvailableAmount + deliveryFee;

  const outOfStockList = basket.items.filter((item) => item.stock.quantity < item.quantity);
  const depletedList = outOfStockList.filter((item) => item.stock.quantity === 0);

  const groupedItems = basket.items.reduce((result, item) => {
    const existGroup = result.find((group) => group.productId === item.productId);
    if (!existGroup) {
      result.push({
        productId: item.productId,
        productName: item.productName,
        items: [],
      });
    }

    const group = existGroup ?? result.at(-1)!;
    group.items.push(item);

    return result;
  }, [] as ItemGroups[]);

  const [modifyItem, setModifyItem] = useState<BasketProductItem | null>(null);
  const [removeItems, setRemoveItems] = useState<BasketProductItem[]>([]);
  const { products } = useProducts();

  useEffect(() => {
    const uncheckList = Object.fromEntries(depletedList.map((item) => [item.key, false]));
    const newChecks = { ...basketChecks, ...uncheckList };
    setBasketChecks(newChecks);
  }, [basket]);

  const handeBuyChecked = () => {
    const itemKeys = checkedItems.map((i) => i.key);
    const orderItemState: OrderItemState = { orderItemKeys: itemKeys };
    setAppliedCoupon(null);
    navigate('/shop/order', { state: orderItemState });
  };

  const handleBuyItem = (item: BasketProductItem) => {
    const itemKeys = [item.key];
    const orderItemState: OrderItemState = { orderItemKeys: itemKeys };
    navigate('/shop/order', { state: orderItemState });
  };

  const handleRemoved = () => {
    setRemoveItems([]);
  };

  const handleCheck = (item: BasketProductItem, checked: boolean) => {
    setBasketCheck(item.key, checked);
  };

  const handleCheckAll: BareCheckboxProps['onChange'] = (_, checked) => {
    const checkList = Object.fromEntries(basket.items.map(({ key }) => [key, checked]));
    const uncheckList = Object.fromEntries(depletedList.map((item) => [item.key, false]));
    const newChecks = { ...checkList, ...uncheckList };
    setBasketChecks(newChecks);
  };

  if (basket.count === 0) {
    return <EmptyBasket />;
  }
  return (
    <>
      <Page
        type={'page'}
        name={
          i18n.t('shop-basket-basket') // Basket
        }
        fixedBottomPosition={'sticky'}
        fixedBottom={
          <Container
            bottom
            py={'8px'}
            px={'20px'}
            sx={() => ({ zIndex: 1 })}
            background={'paper'}
            stackProps={{
              column: true,
              gap: '8px',
            }}
          >
            <Button
              fullWidth
              disabled={totalOrderAvailableAmount <= 0}
              color={'primary'}
              variant={'contained'}
              onClick={handeBuyChecked}
            >
              {
                i18n.t('shop-basket-buyTotalAmount', {
                  totalAvailableAmount: totalOrderAvailableAmount.toLocaleString(),
                  currency,
                }) // Buy {totalAvailableAmount} {currency}
              }
            </Button>
            <Typography variant={'caption'} color={'typography.tertiary'} textAlign={'center'}>
              {
                i18n.t('shop-basket-reviewAndConsent') // I have reviewed and consent to the above order.
              }
            </Typography>
          </Container>
        }
      >
        <Container px={'20px'} py={'12px'}>
          <Stack row alignItems={'center'} gap={'14px'}>
            <Checkbox onChange={handleCheckAll} checked={isAllChecked} />
            <Stack column gap={'6px'} flex={1}>
              {/*<LogoDelivery color={'primary'}/>*/}
              {delivery.isFree ? (
                <Stack row alignItems={'center'}>
                  <Typography
                    variant={'FootNote_B'}
                    color={'primary'}
                    component={'span'}
                    lineHeight={'2'}
                  >
                    {
                      i18n.t('shop-basket-freeDeliveryAvailable') // Free Delivery is available!
                    }
                  </Typography>
                </Stack>
              ) : (
                <Typography variant={'FootNote'} color={'typography.secondary'}>
                  <Typography variant={'FootNote_B'} color={'primary'}>
                    {delivery.left.toLocaleString()} {currency}{' '}
                  </Typography>
                  {
                    i18n.t('shop-basket-leftForFreeDelivery') // Left for Free Delivery
                  }
                </Typography>
              )}
              {/*<Progress type={'linear'} value={delivery.fulfilledPercentage * 100} />*/}
            </Stack>

            <IconButton
              iconProps={{
                type: 'circle',
                size: '0.75em',
                backgroundColor: 'typography.quaternary',
              }}
            >
              question_mark
            </IconButton>
          </Stack>
        </Container>
        <Divider type={'line'} color={'fill'} />
        <Stack column>
          {basket.items.length > 0 &&
            groupedItems
              .map((group) => {
                return group.items.map((item, idx, items) => {
                  const key = item.key;
                  return (
                    <Fragment key={key}>
                      <BasketItem
                        item={item}
                        checked={basketChecks[item.key]}
                        onDelete={(item) => {
                          setRemoveItems((prev) => [...prev, item]);
                        }}
                        onModify={() => {
                          setModifyItem(item);
                        }}
                        onOrder={(item) => {
                          handleBuyItem(item);
                        }}
                        onCheck={handleCheck}
                      />
                      {idx !== items.length - 1 && <Divider type={'line'} color={'fill'} />}
                    </Fragment>
                  );
                });
              })
              .flat(1)}
        </Stack>
        <Divider type={'box'} color={'fill'} />
        <Area
          title={
            i18n.t('shop-basket-paymentDetails') // Payment Details
          }
        >
          <Stack column>
            <Container
              px={'20px'}
              py={'12px'}
              stackProps={{
                row: true,
                justifyContent: 'space-evenly',
              }}
            >
              <Typography
                flex={1}
                textAlign={'left'}
                color={'typography.secondary'}
                whiteSpace={'nowrap'}
              >
                {
                  i18n.t('shop-basket-itemCost') // Item Cost
                }
              </Typography>
              <Container
                stackProps={{
                  display: 'column',
                }}
              >
                <Typography flex={1} variant={'BaseS_B'} textAlign={'right'}>
                  {checkedAvailableAmount.toLocaleString()} {currency}
                </Typography>
                {checkedAmount !== checkedAvailableAmount ? (
                  <Typography
                    strike={checkedAmount !== checkedAvailableAmount}
                    flex={1}
                    lineHeight={1}
                    variant={'FootNote'}
                    textAlign={'right'}
                    color={'typography.tertiary'}
                  >
                    {checkedAmount.toLocaleString()} {currency}
                  </Typography>
                ) : null}
              </Container>
            </Container>

            <Container
              px={'20px'}
              py={'12px'}
              stackProps={{
                row: true,
                justifyContent: 'space-evenly',
              }}
            >
              <Typography flex={1} textAlign={'left'} color={'typography.secondary'}>
                {
                  i18n.t('shop-basket-deliveryFee') // Delivery Fee
                }
              </Typography>
              <Typography variant={'BaseS_B'} textAlign={'right'}>
                {deliveryFee === 0
                  ? i18n.t('shop-basket-free')
                  : `${deliveryFee.toLocaleString()}${currency}`}
              </Typography>
            </Container>
          </Stack>
          <Divider type={'line'} color={'fill'} />
          <Container
            py={'12px'}
            px={'20px'}
            stackProps={{
              row: true,
              justifyContent: 'space-evenly',
            }}
          >
            <Typography
              variant={'BaseM_B'}
              flex={1}
              textAlign={'left'}
              color={'typography.secondary'}
            >
              {
                i18n.t('shop-basket-total') // TOTAL
              }
            </Typography>
            <Typography variant={'BaseM_B'} color={'primary'} flex={1} textAlign={'right'}>
              {totalOrderAvailableAmount.toLocaleString()} {currency}
            </Typography>
          </Container>
        </Area>
        <Divider type={'box'} color={'fill'} />
        <ProductList
          type={'simple'}
          title={
            i18n.t('shop-basket-howAboutThis') // How about this?
          }
          products={products}
          trail={'AD'}
        />
      </Page>

      {modifyItem && (
        <ModifyItemPopover
          open={!!modifyItem}
          onClose={() => setModifyItem(null)}
          item={modifyItem}
        />
      )}
      {removeItems.length > 0 && (
        <RemoveItemsPopover
          open={removeItems.length > 0}
          onClose={() => setRemoveItems([])}
          removeItems={removeItems}
          onRemoved={handleRemoved}
        />
      )}
    </>
  );
}

function EmptyBasket() {
  const { i18n } = useTranslation();
  const { products } = useProducts();
  const navigate = useNavigate();
  const gotoShopping = () => {
    navigate('/shop');
  };

  return (
    <Page
      type={'page'}
      name={'Basket'}
      fixedBottomPosition={'sticky'}
      fixedBottom={
        <Container
          py={'8px'}
          px={'20px'}
          background={'paper'}
          stackProps={{
            column: true,
            gap: '8px',
          }}
        >
          <Button fullWidth color={'primary'} variant={'contained'} onClick={gotoShopping}>
            {
              i18n.t('shop-basket-returnToShopping') // Return to Shopping
            }
          </Button>
        </Container>
      }
    >
      <Spotlight
        size={'large'}
        textAlign={'center'}
        headline={
          i18n.t('shop-basket-cartEmpty') // Oops! Your cart is empty!
        }
        headlineProps={{ color: 'typography.tertiary' }}
      />
      <Divider type={'box'} color={'fill'} />
      <ProductList
        type={'simple'}
        title={i18n.t('shop-basket-howAboutThis')}
        products={products}
        trail={'AD'}
      />
    </Page>
  );
}
