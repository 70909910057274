import Container from '~/components/Container';
import ProductList from '~/components/ProductList';
import { useTranslation } from 'react-i18next';
import useProductsKeepState from '~/hooks/useProductsKeepState';
import { useState } from 'react';
import { PRODUCT_ORDER_BY } from '../constants';
import Tabs from '~/components/Tabs';
import { Typography } from '@mui/material';

export default function MustHaveProducts() {
  const { i18n } = useTranslation();
  const [selectedOrderBy, setSelectedOrderBy] = useState<string>('recommendation');
  const { products, hasNextPage, next } = useProductsKeepState(
    `main:${selectedOrderBy}`,
    {
      orderBy: selectedOrderBy?.split(':')[0] ?? undefined,
      orderMethod: selectedOrderBy?.split(':')[1] ?? undefined,
    },
    { pageSize: 8 }
  );
  return (
    <>
      <Container pb={'4px'}>
        <Typography variant={'BaseM_B'} sx={{ px: '20px' }}>
          {i18n.t('shop-main-dontMissThis')}
        </Typography>
        <Tabs
          scrollable
          value={selectedOrderBy}
          tabLabelVariant={'BaseS'}
          onChange={(_, tab) => setSelectedOrderBy(tab)}
          sx={{
            px: '20px',
          }}
          tabs={[
            ...PRODUCT_ORDER_BY.map((orderBy) => ({
              label: i18n.t(orderBy.label),
              value: orderBy.value,
            })),
          ]}
        ></Tabs>
      </Container>
      <Container>
        <ProductList
          type={'pair'}
          products={products}
          onInfiniteScroll={
            hasNextPage
              ? () => {
                  next();
                }
              : undefined
          }
        />
      </Container>
    </>
  );
}
