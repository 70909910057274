import { ReactNode, ElementType } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { default as MuiBox, BoxProps as MuiBoxProps } from '@mui/material/Box';
import {
  Fill,
  isFill,
  isPaper,
  Paper,
  Transparent,
  Default,
} from '~/components/commons/ColorNames.ts';
import { default as NavigationBar, NavigationBarProps } from '~/components/NavigationBar.tsx';
import LogoKupLink from '~/components/LogoKupLink.tsx';
import IconButton from '~/components/IconButton.tsx';
import Typography from '~/components/Typography.tsx';
import useSafeArea from '~/hooks/useSafeArea.ts';

export type PageType = 'main' | 'page' | 'modal';

export type PageBackgroundColor = Fill | Paper | Transparent | Default;

type CustomProps<E extends ElementType> = {
  name?: ReactNode;
  title?: string;
  type?: PageType;
  end?: ReactNode;
  fixedBottomPosition?: 'sticky' | 'fixed' | 'absolute' | 'relative' | 'static';
  fixedBottomProps?: MuiBoxProps;
  fixedBottom?: ReactNode;
  navigationBarProps?: NavigationBarProps;
  onCloseMessage?: () => void;
  backgroundColor?: PageBackgroundColor;
  closePath?: string;
  component?: E;
};

export type PageProps<E extends ElementType> = MuiBoxProps<E> & CustomProps<E>;

export default function Page<E extends ElementType>(props: PageProps<E>) {
  const {
    name,
    title,
    type = 'modal',
    fixedBottomPosition = 'fixed',
    fixedBottomProps = {},
    navigationBarProps = {},
    children,
    fixedBottom,
    backgroundColor = 'paper',
    closePath,
    end,
    ...restProps
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const { viewportHeight } = useSafeArea();
  const getPredefinedNavigationBarProps = (type: PageType): NavigationBarProps =>
    type === 'main'
      ? {
          lead: <LogoKupLink color={'typography.tertiary'} />,
          position: { position: 'relative', top: 0, left: 0, right: 0 },
        }
      : {
          lead: (
            <>
              <IconButton
                touchArea={'16px'}
                onClick={() => {
                  if (location.key === 'default') {
                    navigate('/');
                  } else if (closePath) {
                    navigate(closePath);
                  } else {
                    navigate(-1);
                  }
                }}
                iconProps={{
                  width: '20px',
                  color: 'typography.tertiary',
                }}
              >
                {type === 'page' ? 'back' : 'close'}
              </IconButton>
              <Typography variant={'BaseM'} ml={'8px'} whiteSpace={'nowrap'}>
                {name}
              </Typography>
            </>
          ),
          center: <Typography variant={'BaseM_B'}>{title}</Typography>,
          background: 'paper',
        };
  const preDefinedNavigationBarProps = getPredefinedNavigationBarProps(type);

  return (
    <MuiBox
      {...restProps}
      sx={(theme) => ({
        minHeight: `${viewportHeight}px`,
        padding: 0,
        backgroundColor:
          backgroundColor && isFill(backgroundColor)
            ? theme.palette.fill.primary
            : backgroundColor && isPaper(backgroundColor)
              ? theme.palette.background.paper
              : backgroundColor,
      })}
    >
      <NavigationBar {...Object.assign(preDefinedNavigationBarProps, navigationBarProps)} />
      {children}
      {fixedBottom ? (
        <MuiBox
          style={{
            bottom: 0,
            left: 0,
            right: 0,
            position: fixedBottomPosition,
            zIndex: 999, // NaverMap 내 요소들의 z-index 가 100 이어서 오버랩 되는 것을 방지 하기 위함
          }}
          {...fixedBottomProps}
        >
          {fixedBottom}
        </MuiBox>
      ) : null}
      {end && end}
    </MuiBox>
  );
}
