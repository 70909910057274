import Typography from '~/components/Typography.tsx';
import i18n from '~/i18n';
import useTodayCutOff from '../hooks/useTodayCutOff';

export default function OnTourCouponTimeLimit() {
  const { cutOffRemains } = useTodayCutOff();

  return (
    <Typography variant="TitleM_B" sx={{ mt: '12px' }}>
      {i18n.t('home-main-coupon-offer-ends', { time: cutOffRemains.text })}
    </Typography>
  );
}
