import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useKupStore from '~kup/store';

import Card from '~/components/Card.tsx';
import Box from '~/components/Box.tsx';
import Typography from '~/components/Typography.tsx';
import Progress from '~/components/Progress.tsx';
import Icon from '~/components/Icon';
import ButtonBase from '~/components/ButtonBase.tsx';
import useDeliveryCutOff from '~/pages/shop/hooks/useDeliveryCutOff.ts';
import useDelivery from '~/pages/shop/hooks/useDelivery.ts';
import Logo from '~/components/Logo';

import { ButtonBaseProps } from '@mui/material';

export default function OrderDeliveryWithThreshold() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { currency, basket, deliveryAddress } = useKupStore((state) => ({
    currency: state.currency,
    basket: state.basket,
    deliveryAddress: state.deliveryAddress,
  }));

  const { isPassedTodayCutOff, cutOffHoursKST, deliveryHoursKST } = useDeliveryCutOff();

  const amount = basket.availableAmount;

  const { left, fulfilledPercentage, isFree } = useDelivery(amount);

  const handleAddressClick = () => {
    navigate('/shop/address');
  };

  const handleOrderNowClick = () => {
    navigate('/shop/basket');
  };

  return (
    <Card>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          mb: '8px',
        }}
      >
        {isFree ? (
          <Typography variant={'BaseS_B'}>
            <Typography
              component={'span'}
              variant={'inherit'}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Logo color={'primary'} height={'16px'} sx={{ mx: '4px' }}>
                delivery
              </Logo>
              {i18n.t(isPassedTodayCutOff ? 'shop-main-byTomorrow' : 'shop-main-byToday', {
                hours: cutOffHoursKST,
              })}
              <br />
            </Typography>
            {
              i18n.t('shop-main-arriveBefore', {
                deliveryHour: deliveryHoursKST.toString().padStart(2, '0') + ':00',
              }) // Get it by {{deliveryHour}}:00(KST)  {{deliveryDay}}
            }
          </Typography>
        ) : (
          <Typography variant={'BaseS_B'}>
            <Typography
              component={'span'}
              variant={'inherit'}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {i18n.t('shop-main-free')}
              <Logo color={'primary'} height={'1em'} sx={{ mx: '4px' }}>
                delivery
              </Logo>
              {i18n.t(isPassedTodayCutOff ? 'shop-main-byTomorrow' : 'shop-main-byToday', {
                hours: cutOffHoursKST,
              })}
              <br />
            </Typography>
            <Typography component={'span'} color={'primary'}>
              {i18n.t('shop-main-addAmountOrMore', {
                amount: `${left.toLocaleString()}${currency}`,
              })}
            </Typography>
          </Typography>
        )}
      </Box>
      <Box>
        <Progress
          type={'linear'}
          variant={'determinate'}
          value={amount === 0 ? 0 : fulfilledPercentage * 100}
        />
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            color: theme.palette.typography.secondary,
            pt: '4px',
          })}
        >
          <Typography variant={'Caption'}>
            {i18n.t('shop-main-inYourCart', {
              amount: `${basket.amount.toLocaleString()}${currency}`,
            })}
          </Typography>
        </Box>
      </Box>
      <Box pt={'8px'} pb={'4px'}>
        {!deliveryAddress ? (
          <OrderDeliveryButton onClick={handleAddressClick}>
            <Icon size={'1.5em'} width={'24px'} color={'primary'}>
              place
            </Icon>
            <Typography flex={1} variant={'FootNote_B'}>
              {i18n.t('shop-main-whereAreYouStaying')}
            </Typography>
            <Icon size={'1.5em'} width={'24px'} color={'typography.tertiary'}>
              navigate_next
            </Icon>
          </OrderDeliveryButton>
        ) : isFree ? (
          <OrderDeliveryButton onClick={handleOrderNowClick}>
            <Typography flex={1} textAlign={'center'} variant={'FootNote_B'}>
              {
                i18n.t('shop-main-orderNow') // Order Now!
              }
            </Typography>
          </OrderDeliveryButton>
        ) : null}
      </Box>
    </Card>
  );
}

function OrderDeliveryButton(props: {
  onClick: ButtonBaseProps['onClick'];
  children: ButtonBaseProps['children'];
}) {
  const { onClick, children } = props;
  return (
    <ButtonBase
      sx={(theme) => ({
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: theme.palette.fill.primary,
        paddingX: '16px',
        paddingY: '12px',
        borderRadius: '8px',
        borderTopLeftRadius: '0px',
      })}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
}
