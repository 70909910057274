import Box from '~/components/Box';
import { useNavigate } from 'react-router-dom';
import Container from '~/components/Container.tsx';
import ProductList from '~/components/ProductList.tsx';
import Typography from '~/components/Typography.tsx';
import useProductsKeepState from '~/hooks/useProductsKeepState.ts';
import { ProductTag } from '~kup/models/Product.ts';
import { useTranslation } from 'react-i18next';

export default function TagProductSection(props: { tag: ProductTag }) {
  const { tag } = props;
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const headerImage = tag.headImageUrls?.[0]?.url;
  const headline = tag.headline;
  const { products } = useProductsKeepState(
    `tag:${tag.name}`,
    { tags: [tag.name] },
    { pageSize: 8 }
  );
  return (
    <Box
      sx={{
        overflow: 'clip',
      }}
    >
      <TagSectionHeader headerImage={headerImage} headline={headline} />
      <ProductList
        products={products}
        type={'horizontal'}
        background={'transparent'}
        moreProduct={i18n.t('home-main-moreTagProducts', { tagName: tag.name?.toUpperCase() ?? '' })}
        onClickLinkMore={() => {
          navigate(`/shop#tag/${tag.name}`);
        }}
      />
    </Box>
  );
}

function TagSectionHeader(props: { headerImage: string; headline: string }) {
  const { headerImage, headline } = props;
  return (
    <Container>
      <Container
        sx={{
          position: 'relative',
          height: '248px',
        }}
      >
        <img
          src={headerImage}
          style={{
            width: '100%',
            minWidth: '100%',
            height: '100%',
            filter: 'blur(100px)',
            transform: 'scale(1.3)',
            borderRadius: '100px',
          }}
        />

        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexDirection: 'row',
            gap: '20px',
            padding: '32px 20px',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Typography
            variant={'TitleS_B'}
            fontWeight={'bold'}
            component={'p'}
            color={'white'}
            whiteSpace={'pre-wrap'}
          >
            {headline}
          </Typography>
          <img
            src={headerImage}
            style={{
              maxHeight: '100%',
              maxWidth: '60%',
              objectFit: 'contain',
              overflow: 'clip',
              borderRadius: '8px',
            }}
          />
        </Box>
      </Container>
    </Container>
  );
}
