import { ReactNode } from 'react';
// import {default as MuiListItem} from "@mui/material/ListItem";
import { default as MuiListItemIcon } from '@mui/material/ListItemIcon';
import {
  default as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material/ListItemButton';
import { default as MuiCheckbox } from '@mui/material/Checkbox';

import Box from './Box';
import Headline, { HeadlineProps } from './Headline';
import Typography, { TypographyProps } from './Typography';
import Stack from './Stack';

type SimpleListItemOwnProps = {
  lead?: ReactNode;
  trail?: ReactNode;
  children?: ReactNode;
  backgroundColor?: string;
  onClick?: () => void;
};

type CheckBoxListItemOwnProps = {
  checkBox: true;
  checked: boolean;
  onCheck: (checked: boolean) => void;
};

export type BaseListItemProps = SimpleListItemOwnProps &
  Partial<CheckBoxListItemOwnProps> &
  MuiListItemButtonProps;

function isCheckableListItemProps(
  props: Partial<BaseListItemProps>
): props is CheckBoxListItemOwnProps {
  return props.checkBox === true && props.checked !== undefined && props.onCheck !== undefined;
}

export function BaseListItem(props: BaseListItemProps) {
  const { disabled, lead, trail, children, backgroundColor, onClick, disableRipple } = props;
  return (
    <Box sx={{ backgroundColor: backgroundColor ?? 'transparent' }}>
      <MuiListItemButton
        disableRipple={disableRipple}
        disabled={disabled}
        onClick={onClick}
        sx={{
          py: '12px',
          px: '20px',
          gap: '14px',
        }}
      >
        {isCheckableListItemProps(props) ? (
          <MuiListItemIcon>
            <MuiCheckbox
              sx={{
                marginLeft: '-12px',
                marginRight: '-12px',
              }}
              checked={props.checked}
              onChange={(_, value) => props.onCheck(value)}
            />
          </MuiListItemIcon>
        ) : null}
        {lead && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {lead}
          </Box>
        )}
        {children}
        {trail && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {trail}
          </Box>
        )}
      </MuiListItemButton>
    </Box>
  );
}

type HeadlineListItemProps = Omit<BaseListItemProps, 'children'> & HeadlineProps;

export function HeadlineListItem(props: HeadlineListItemProps) {
  const {
    lead,
    trail,
    checkBox,
    checked,
    onCheck,
    disabled,
    disableStrike,
    onClick,
    disableRipple,
    backgroundColor,
    ...headlineProps
  } = props;
  return (
    <BaseListItem
      disableRipple={disableRipple}
      disabled={disabled}
      checkBox={checkBox}
      checked={checked}
      onCheck={onCheck}
      lead={lead}
      trail={trail}
      onClick={onClick}
      backgroundColor={backgroundColor}
    >
      <Box flex={1}>
        <Headline {...headlineProps} strikeHeadline={!disableStrike && disabled} />
      </Box>
    </BaseListItem>
  );
}

type ValueListItemProps = Omit<BaseListItemProps, 'children'> & {
  head: string;
  subHead?: string;
  value: string;
  subValue?: string;
  headProps?: TypographyProps;
  subHeadProps?: TypographyProps;
  valueProps?: TypographyProps;
  subValueProps?: TypographyProps;
};

export function ValueListItem(props: ValueListItemProps) {
  const {
    head,
    subHead,
    value,
    subValue,
    headProps = {},
    subHeadProps = { color: 'typography.tertiary' },
    valueProps = {},
    subValueProps = { color: 'typography.tertiary' },
    disabled,
    onClick,
  } = props;
  return (
    <BaseListItem
      disabled={disabled}
      onClick={onClick}
      lead={
        <Stack column>
          <Typography
            variant={'BaseS'}
            color={'typography.secondary'}
            {...headProps}
            strike={disabled}
          >
            {head}
          </Typography>
          <Typography variant={'Caption'} color={'typography.primary'} {...subHeadProps}>
            {subHead}
          </Typography>
        </Stack>
      }
    >
      <Stack column flex={1}>
        <Typography
          variant={'BaseM_B'}
          color={'typography.primary'}
          textAlign={'right'}
          {...valueProps}
        >
          {value}
        </Typography>
        <Typography
          variant={'Caption'}
          color={'typography.primary'}
          textAlign={'right'}
          {...subValueProps}
        >
          {subValue}
        </Typography>
      </Stack>
    </BaseListItem>
  );
}

type ListItemProps =
  | ({ type: 'headline' } & HeadlineListItemProps)
  | ({ type: 'value' } & ValueListItemProps)
  | ({
      type: 'base';
    } & BaseListItemProps);

export default function ListItem(props: ListItemProps) {
  const { type = 'base', ...restProps } = props;
  if (type === 'headline') {
    return <HeadlineListItem {...(restProps as HeadlineListItemProps)} />;
  }
  if (type === 'value') {
    return <ValueListItem {...(restProps as ValueListItemProps)} />;
  }

  return <BaseListItem {...(restProps as BaseListItemProps)} />;
}

// export default function HeadLineListItem(props:ListItemProps){
//   return(
//       <ListItem>
//         <Headline size={'small'} headline={'HeadLine'} subHeadline={'subheadline'} description={'description\r\bdescription'}/>
//       </ListItem>
//   )
// }
//
// export default function ValueListItem(props:ListItemProps){
//   return(
//       <ListItem>
//
//       </ListItem>
//   )
// }
