import { useEffect, useState } from 'react';
import { ProductCategory } from '~kup/models/Product.ts';
import { getProductCategories, getProductCategoryTree } from '~kup/controllers/product.ts';

type UseCategories = { categories: ProductCategory[] };

type ProductCategoryQuery = {
  parentId?: string | null;
  parentSlug?: string | null;
};

export default function useProductCategories(query: ProductCategoryQuery = {}): UseCategories {
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const { parentId, parentSlug } = query;

  useEffect(() => {
    getProductCategories({ parentId, parentSlug }).then((productCategories) =>
      setCategories(productCategories)
    );
  }, []);
  return { categories };
}

export function useProductCategoryTree(): UseCategories {
  const [categories, setCategories] = useState<ProductCategory[]>([]);

  useEffect(() => {
    getProductCategoryTree().then((productCategories) => setCategories(productCategories));
  }, []);
  return { categories };
}
