import { useEffect, useState } from 'react';
import { getProductTags } from '~kup/controllers/product.ts';
import { ProductTag } from '~kup/models/Product.ts';
import * as Sentry from "@sentry/react";

export default function useTags(keyword?: string, isHighlight: boolean = false) {
  const [tags, setTags] = useState<ProductTag[]>([]);
  useEffect(() => {
    getProductTags(keyword, isHighlight)
      .then((tags) => {
        const sorted = tags.sort((a, b) => {
          if (a.orderWeight === b.orderWeight) return 0;
          return a.orderWeight > b.orderWeight ? -1 : 1;
        });
        return setTags(sorted);
      })
      .catch((e)=>{
        Sentry.captureException(e);
      });
  }, [isHighlight]);

  return tags;
}
