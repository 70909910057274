import { useTranslation } from 'react-i18next';
import Container from '~/components/Container';
import Typography from '~/components/Typography';
import Box from '~/components/Box';
import Stack from '~/components/Stack';
import Button from '~/components/Button';
import Icon from '~/components/Icon';
import Grid from '@mui/material/Grid';
import OnTourCouponTimeLimit from '../components/OnTourCouponTimeLimit';
import { useEffect } from 'react';
import { BenefitTypeEnum, ICoupon } from '~/services/kup/models/Coupon';
import { useState } from 'react';
import { getIssuableCoupons, issueCoupon } from '~/services/kup/controllers/coupon';
import { useSnackBar } from '~/contexts/SnackBarContext';

function OnTourCouponCard({ coupon }: { coupon: ICoupon }) {
  return (
    <Box
      sx={{
        flex: 1,
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        py: '16px',
        border: '1px solid',
        borderColor: 'primary.main',
      }}
    >
      <div
        style={{
          backgroundColor: '#e6ebf5',
          width: '13px',
          height: '26px',
          borderTopLeftRadius: '26px',
          borderBottomLeftRadius: '26px',
          border: '1px solid',
          borderRight: 'none',
          borderColor: '#1a73e8',
          position: 'absolute',
          top: '50%',
          right: '-1px',
          transform: 'translateY(-50%)',
        }}
      ></div>
      <Stack>
        <Typography
          variant="TitleL_B"
          color="primary"
          sx={{ textAlign: 'center', fontWeight: 700 }}
        >
          {coupon.benefitValue.toLocaleString()}
          {coupon.benefitType === BenefitTypeEnum.DISCOUNT_RATE ? '%' : '₩'}
        </Typography>
        <Typography
          variant="Caption_B"
          color="primary"
          sx={{ textAlign: 'center', fontWeight: 700 }}
        >
          {coupon.description}
        </Typography>
      </Stack>
    </Box>
  );
}

export default function OnTourCouponSection() {
  const { i18n } = useTranslation();
  const { showSnackBar } = useSnackBar();
  const [onTourCoupons, setOnTourCoupons] = useState<ICoupon[]>([]);
  const isIssuableCoupon = onTourCoupons.filter((coupon) => !coupon.isIssuable).length === 0;

  useEffect(() => {
    getIssuableCoupons({ onTour: true }).then(setOnTourCoupons);
  }, []);

  const handleIssueCoupons = async () => {
    await Promise.all(
      onTourCoupons.map((coupon) => {
        issueCoupon(coupon.id).then(() => {
          showSnackBar({
            message: i18n.t('shop-coupon-couponsAreIssued'),
            lift: 120,
          });
          getIssuableCoupons({ onTour: true }).then(setOnTourCoupons);
        });
      })
    );
  };

  if (onTourCoupons.length === 0) return null;

  return (
    <Container px={'20px'} py={'20px'}>
      <Stack justifyContent="center" alignItems="center">
        <Typography
          variant="FootNote"
          color="primary"
          sx={{
            backgroundColor: 'rgba(47, 128, 237, 0.1)',
            px: '6px',
            py: '2px',
            borderRadius: '6px',
            width: 'fit-content',
            display: 'block',
          }}
        >
          {i18n.t('home-main-coupon-exclusive')}
        </Typography>

        <OnTourCouponTimeLimit />

        <Typography variant="FootNote" color="typography.secondary" sx={{ mt: '4px' }}>
          {i18n.t('home-main-coupon-available-only')}
        </Typography>

        <Typography variant="FootNote" color="typography.secondary">
          {i18n.t('home-main-coupon-enjoy-lowest')}
        </Typography>
      </Stack>

      <Grid container columnSpacing="12px" pt="16px">
        {onTourCoupons.map((coupon) => (
          <Grid item xs={onTourCoupons.length === 1 ? 12 : 6}>
            <OnTourCouponCard coupon={coupon} />
          </Grid>
        ))}
      </Grid>

      <Stack sx={{ pt: '16px', textAlign: 'center' }}>
        <Button
          fullWidth
          size="small"
          disabled={!isIssuableCoupon}
          startIcon={<Icon>download</Icon>}
          onClick={() => handleIssueCoupons()}
        >
          {isIssuableCoupon
            ? i18n.t('home-main-coupon-get')
            : i18n.t('shop-coupon-allAvailableCouponsHaveBeenIssued')}
        </Button>

        <Typography
          variant="FootNote"
          color="typography.secondary"
          sx={{ textAlign: 'center', mt: '12px' }}
        >
          {i18n.t('home-main-coupon-valid-24h')}
        </Typography>
      </Stack>
    </Container>
  );
}
