export type AllCategoryProps = {
  width?: string;
  height?: string;
};
export default function IconAllCategory(props: AllCategoryProps) {
  const { width = '41px', height = '40px' } = props;
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.875 9C6.875 7.34315 8.21815 6 9.875 6H16.875C18.5319 6 19.875 7.34315 19.875 9V16C19.875 17.6569 18.5319 19 16.875 19H9.875C8.21815 19 6.875 17.6569 6.875 16V9Z"
        fill="#101010"
        fill-opacity="0.3"
      />
      <path
        d="M6.875 24C6.875 22.3431 8.21815 21 9.875 21H16.875C18.5319 21 19.875 22.3431 19.875 24V31C19.875 32.6569 18.5319 34 16.875 34H9.875C8.21815 34 6.875 32.6569 6.875 31V24Z"
        fill="#101010"
        fill-opacity="0.16"
      />
      <path
        d="M21.875 9C21.875 7.34315 23.2181 6 24.875 6H31.875C33.5319 6 34.875 7.34315 34.875 9V16C34.875 17.6569 33.5319 19 31.875 19H24.875C23.2181 19 21.875 17.6569 21.875 16V9Z"
        fill="#101010"
        fill-opacity="0.16"
      />
      <path
        d="M21.875 24C21.875 22.3431 23.2181 21 24.875 21H31.875C33.5319 21 34.875 22.3431 34.875 24V31C34.875 32.6569 33.5319 34 31.875 34H24.875C23.2181 34 21.875 32.6569 21.875 31V24Z"
        fill="#101010"
        fill-opacity="0.16"
      />
    </svg>
  );
}
