import { Slice } from '~common/store';
import Basket from '~kup/models/Basket.ts';
import { BasketChecks, Contact, DeliveryAddress } from '~kup/models/types.ts';
import { PAYMENT_METHOD } from '~/pages/shop/constants';
import { IIssuedCoupon } from '~kup/models/Coupon.ts';

export interface ShopSlice {
  currency: string;
  freeDeliveryThreshold: number;
  deliveryFee: number;
  basket: Basket;
  basketChecks: BasketChecks;
  deliveryAddress: DeliveryAddress | null;
  customerName: string;
  customerEmail: string;
  paymentMethod: string;
  inputAddress: string | null;
  contact: Contact | null;
  appliedCoupon: IIssuedCoupon | null;
  checkOrderPopup: boolean;
  setCheckOrderPopup: (check: boolean) => void;
  setCustomerName: (customerName: string) => void;
  setCustomerEmail: (customerName: string) => void;
  setPaymentMethod: (paymentMethod: string) => void;
  setInputAddress: (inputAddress: string) => void;
  setContact: (contact: Contact) => void;
  setAppliedCoupon: (issuedCoupon: IIssuedCoupon | null) => void;
  setDeliveryAddress: (address: DeliveryAddress | null) => void;
  setBasket: (basket: Basket) => void;
  setBasketCheck: (key: string, check: boolean) => void;
  setBasketChecks: (checks: BasketChecks) => void;
  syncBasket: () => Promise<void>;
}

export interface PersistedShopSlice {
  currency: string;
  inputAddress: string | null;
  deliveryAddress: DeliveryAddress | null;
  contact: Contact | null;
  appliedCoupon: IIssuedCoupon | null;
  basketChecks: BasketChecks;
  customerName: string;
  customerEmail: string;
  checkOrderPopup: boolean;
}

export const persistShopSlice: (state: ShopSlice) => PersistedShopSlice = (state) => ({
  currency: state.currency,
  inputAddress: state.inputAddress,
  deliveryAddress: state.deliveryAddress,
  contact: state.contact,
  appliedCoupon: state.appliedCoupon,
  basketChecks: state.basketChecks,
  customerName: state.customerName,
  customerEmail: state.customerEmail,
  checkOrderPopup: state.checkOrderPopup,
});

export const createShopSlice: Slice<ShopSlice> = (set, get) => ({
  currency: 'KRW',
  freeDeliveryThreshold: 0,
  deliveryFee: 0,
  customerName: '',
  customerEmail: '',
  paymentMethod: PAYMENT_METHOD[0].value,
  inputAddress: '',
  deliveryAddress: null,
  contact: null,
  appliedCoupon: null,
  basket: Basket.EMPTY_BASKET,
  basketChecks: {},
  checkOrderPopup: false,
  setCheckOrderPopup: (check: boolean) => set(() => ({ checkOrderPopup: check })),
  setCustomerName: (customerName: string) => set(() => ({ customerName })),
  setCustomerEmail: (customerEmail: string) => set(() => ({ customerEmail })),
  setPaymentMethod: (paymentMethod: string) => set(() => ({ paymentMethod })),
  setInputAddress: (inputAddress: string) => set(() => ({ inputAddress })),
  setDeliveryAddress: (deliveryAddress: DeliveryAddress | null) => set(() => ({ deliveryAddress })),
  setContact: (contact: Contact) => {
    set(() => ({ contact }));
  },
  setAppliedCoupon: (issuedCoupon: IIssuedCoupon | null) => {
    set(() => ({ appliedCoupon: issuedCoupon }));
  },
  setBasket: (basket: Basket) => {
    set((state) => {
      const basketItemKeys = basket.items.map((item) => item.key);
      const validBasketChecks = Object.fromEntries(
        Object.entries(state.basketChecks ?? {}).filter(([key]) => basketItemKeys.includes(key))
      );
      const newBasketChecks = {
        ...Object.fromEntries(basket.items.map((item) => [`${item.key}`, true])),
        ...validBasketChecks,
      };
      return {
        basket,
        basketChecks: newBasketChecks,
      };
    });
  },
  setBasketCheck: (itemKey: string, check: boolean) => {
    set((state) => ({ basketChecks: { ...state.basketChecks, [itemKey]: check } }));
  },
  setBasketChecks: (checks: BasketChecks) => {
    set((state) => ({ basketChecks: { ...state.basketChecks, ...checks } }));
  },
  syncBasket: async () => {
    const syncedBasket = await get().basket.sync();
    set(() => ({ basket: syncedBasket }));
  },
});
